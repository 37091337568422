<template>
  <div class="main">
    <!-- 顶部 -->
    <div class="main-nav-box">
      <div class="main-nav">
        <a href="/home">
          <img class="PC_home_icon" :src="PC_home" lazy />
        </a>
        <div class="small-language-box1" @click.stop="isShow = !isShow">
          <span class="small-language2">Language(ENGLISH)</span>
          <img class="hamburger-img" :src="PC_line" lazy />
        </div>
        <div class="hamburger-mask" v-if="isShow">
          <div class="main-smallnav">
            <a class="app-menu-list" href="/exploreallpass/zh/">
              <div class="smallnav-select-item"><span class="main-nav-content">简体中文</span>
              </div>
            </a>
            <a class="app-menu-list" href="/exploreallpass/zh_tw/">
              <div class="smallnav-select-item"><span class="main-nav-content">繁體中文</span></div>
            </a>
            <a class="app-menu-list" href="/exploreallpass/en/">
              <div class="smallnav-select-item"><span class="main-nav-content smallnav-select-item-active">ENGLISH</span></div>
            </a>
            <a class="app-menu-list" href="/exploreallpass/ko/">
              <div class="smallnav-select-item"><span class="main-nav-content">한국어</span></div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- 轮播 -->
    <div class="main-swiper">
      <div>
        <el-carousel class="bannerImg-box" trigger="click" :interval="7000">
          <el-carousel-item v-for="(item,index) in bannerImg" :key="index">
            <img :src="item" class="slide-img1" />
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <!-- 有效期探游卷 -->
    <div class="main-travel ">
      <div class="train-box">
        <div class="app-container">
          <div class="col-md-12 travel-item-box">
            <img class="tracks-img" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/tracks-1.png" lazy />
            <div class="travel-item-md6" v-for="(item,i) in ticketArr" :key="i" @click="change(item)">
              <div :class="[active===item.id ? 'travel-item1' : 'travel-item']">
                <img class="travel-item-img" :src="item.img" lazy />
                <p class="travel-right">VALID FOR
                  <span class="travel-right-day">
                    <span :style="{'color':item.fontcolor}">{{item.validityDay}}</span>&nbsp;Days</span>
                </p>
                <!-- <p class="travel-title">{{item.ticketName}}</p> -->
                <p class="travel-detail" v-for="(item ,index) in splitBatName(item.describe)" :key="index">{{item}}</p>
                <p class="travel-price" :style="{'color':item.fontcolor}">￥{{item.price}}</p>
              </div>
            </div>
          </div>
        </div>
        <img class="train-img" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/train-1.png" lazy />
        <img class="train-img2" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/train-1.png" lazy />
      </div>
    </div>
    <!-- 套餐内容 -->
    <div class="main-package">
      <div class="app-container">
        <div class="package-imgBox">
          <img class="package-train-img" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/train-2.png" lazy />
          <img class="package-point-img" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/point-1.png" lazy />
        </div>
        <div class="col-md-12  package-content-box">
          <div class="package-content flex-arround">
            <img class="package-star" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/star-right.png" lazy />
            <p class="package-title">Included in the All Pass</p>
            <img class="package-star" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/star-left.png" lazy />
          </div>
        </div>
        <div class="col-md-12">
          <div class="package-riding-content">
            <div class="riding-box">
              <div class="riding-left">
                <img class="riding-img" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/riding-2.png" lazy />
                <img class="riding-copyright" :src="rid_copy" lazy />
              </div>
              <div class="riding-right">
                <div class="right-title-box">
                  <p class="riding-right-title">JR Ticket</p>
                  <p class="riding-right-title2">Can be used for reserved seating</p>
                </div>
                <p class="riding-right-title3">Kansai-Airport Express HARUKA One-way ticket<br>(from Kansai-Airport to Kyoto Station)</p>
                <img :src="ticket_zhu2" alt="" style="width:362px ;" lazy />
              </div>
            </div>
            <img :src="ticket_zhu" style="width: 76%;margin-left: 20px;margin-top: 5px;" lazy />
            <div class="riding-bottom" :class="[active===2 ? 'riding-bottom2' : '']">
              <div class="riding-bottom-1" :class="[active===2 ? 'riding-bottom-1-1' : '']">
                <p class="riding-bottom-t" :class="[active===2 ? 'riding-bottom-t1' : '']" v-for="(item ,index) in splitBatName(planName)" :key="index">{{item}}</p>
                <p class="riding-bottom-p">￥{{planPrice}}</p>
              </div>
              <div class="riding-bottom-2" v-if="planName2 && planPrice2" :class="[active===2 ? 'riding-bottom-2-1' : '']">
                <p class="riding-bottom-t" v-for="(item ,index) in splitBatName(planName2)" :key="index">{{item}}</p>
                <p class="riding-bottom-p">￥{{planPrice2}}</p>
              </div>
              <div class="riding-bottom-3" v-if="planName3 && planPrice3">
                <p class="riding-bottom-t">{{planName3}}</p>
                <p class="riding-bottom-p">￥{{planPrice3}}</p>
              </div>
              <!-- <div class="riding-bottom-4" :class="[active===2 ? 'riding-bottom-42' : '']">
                            <p>※仅一次有效</p>
                            <p>※也能够于天王寺•新大阪站下车</p>
                        </div> -->
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="package-universal-content">
            <div class="universal-left">
              <p class="universal-left-1">Universal Studios Japan 1 Day Studio Pass</p>
              <!-- <p class="universal-left-2">无法想象的感动在影城等待您！</p> -->
              <div class="universal-right">
                <p class="universal-left-3">
                  <span>And don't miss The Wizarding World of Harry Potter™, Minions,<br>and other magnificent worlds
                    enjoyed by kids and adults alike!</span>
                  <span>Exhilarate your senses with a day of play in a park full of endless excitement and thrills!</span>
                </p>
                <img class="universal-img" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/universal-zh1.jpg" lazy />
              </div>
              <div class="universal-left-4">
                <p>※Based on research by USJ LLC into themed establishments based<br>&nbsp;&nbsp;&nbsp;&nbsp;on Nintendo characters and worlds.</p>
              </div>
            </div>
          </div>
        </div>
        <!-- 观光设施共通劵 -->
        <div class="col-md-12 " style="display: flex;justify-content: center;">
          <div class="facilities-content ">
            <div class="facilities-borderWhite">
              <div class="facilities-box flex-arround">
                <img class="facilities-star" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/star-right.png" lazy />
                <p class="facilities-title">{{ticketDescribe}}</p>
                <img class="facilities-star" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/star-left.png" lazy />
              </div>
              <div class="facilities-attention ">
                <p>1. For the latest information regarding each facilities' business hours and notice, please check the official website.</p>
                <p>2. Intervals of 2 hours is required between each facility used.</p>
                <p>3. Repeated use on the same facility is not allowed.</p>
              </div>
              <div class="row area-box">
                <div class="area-md3" v-for="(item,i) in md3" :key="i" @click="activeArea(item)">
                  <div class="all-area" :class="[activeArr.includes(item.id)? 'area-active' : '']">
                    {{item.name}}
                  </div>
                </div>
                <div class="area-md3" v-show="active === 1" v-for="(item,i) in md4" :key="i" @click="activeArea(item)">
                  <div class="all-area" :class="[activeArr.includes(item.id)? 'area-active' : '']">
                    {{item.name}}
                  </div>
                </div>
              </div>
              <div class="row area-box2">
                <div class="area-md5" v-for="(item,i) in md5" :key="i" @click="activeArea(item)">
                  <div class="area2 " :style="{backgroundColor:(activeArr.includes(item.id)?'#ff7b00':'#fff'),color:(activeArr.includes(item.id)?'#fff':'#ff7b00')}">
                    {{item.name}}
                  </div>
                </div>
                <div class="area-md7" v-show="active===1" v-for="(item,i) in md7" :key="i" @click="activeArea(item)">
                  <div class="area3" :style="{backgroundColor:(activeArr.includes(item.id)?'#ff7b00':'#fff'),color:(activeArr.includes(item.id)?'#fff':'#ff7b00')}">
                    {{item.name}}</div>
                </div>
              </div>
            </div>
          </div>
          <img class="train-footer-img1" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/train-footer.png" lazy />
          <img class="train-footer-img2" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/train-footer.png" lazy />
          <img class="train-footer-img3" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/point-2.png" lazy />
        </div>
        <!-- 地区模块 -->
        <div class="facilities-tracks2">
          <img class="tracks-2" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/tracks-2.png" lazy />
          <img class="tracks-3" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/tracks-2.png" alt="" :style="{display:((activeArr.includes(2) || activeArr.includes(3) || activeArr.includes(4) || activeArr.includes(6))?'none':'block')}" lazy />
          <img class="tracks-6" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/tracks-6.png" alt="" v-show="active===1" :style="{display:((activeArr.includes(2) || activeArr.includes(3) || activeArr.includes(4) || activeArr.includes(5) || activeArr.includes(6))?'none':'block')}" lazy />
        </div>
        <div class="col-md-12 " style="display:flex;flex-direction: column;">
          <div class="facilities-detail">
            <div class="app-container">
              <img class="point-3" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/point-3.png" alt="">
              <img class="tracks-2" :style="{display:(activeArr.includes(4)?'none':'block')}" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/tracks-2.png" alt="">
              <img class="point-4" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/point-4.png" alt="" :style="{display:(activeArr.includes(4)?'none':'block')}">
              <img class="point-5" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/point-5.png" alt="" :style="{display:((activeArr.includes(2) || activeArr.includes(3) || activeArr.includes(4) || activeArr.includes(5) || activeArr.includes(6))?'none':'block')}">
              <img class="point-cart" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/cart.png" alt="" :style="{display:((activeArr.includes(2) || activeArr.includes(3) || activeArr.includes(4) || activeArr.includes(5) || activeArr.includes(6))?'none':'block')}">
              <img class="tracks-6" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/tracks-6.png" alt="" :style="{display:((activeArr.includes(2) || activeArr.includes(3) || activeArr.includes(4) || activeArr.includes(5) || activeArr.includes(6))?'none':'block')}">
              <img class="tracks-point-2" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/point-2.png" alt="" :style="{display:((activeArr.includes(2) || activeArr.includes(3) || activeArr.includes(4) || activeArr.includes(5) || activeArr.includes(6))?'none':'block')}">
              <img class="tracks-2" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/tracks-2.png" alt="" :style="{display:(activeArr.includes(2)?'none':'block')}">
              <img class="tracks-fox" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/tracks-fox.png" alt="">
              <img class="tracks-3" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/tracks-2.png" alt="" :style="{display:((activeArr.includes(2) || activeArr.includes(3) || activeArr.includes(4) || activeArr.includes(6))?'none':'block')}">
              <img class="tracks-point-3" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/point-3.png" alt="" :style="{display:((activeArr.includes(2) || activeArr.includes(3) || activeArr.includes(4) || activeArr.includes(5) || activeArr.includes(6))?'none':'block')}">
              <img class="train-yellow" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/train-yellow.png" alt="" :style="{display:((activeArr.includes(1))?'block':'none')}">
              <img class="tracks-door" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/door.png" alt="" :style="{display:((activeArr.includes(1))?'block':'none')}">
              <img class="tracks-middle" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/tracks-middle.png" alt="" :style="{display:((activeArr.includes(1))?'blobk':'none')}">
              <img class="tracks-middle2" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/tracks-middle.png" alt="" :style="{display:((activeArr.includes(1))?'blobk':'none')}">
              <div class="detail-item-list col-md-12">
                <div class="col-md-4" v-for="(item,i) in showList" :key="i" @click="open(item)">
                  <div class="detail-item-box" v-if="active === 1">
                    <div class="detail-item" id="detail-item">
                      <img class="detail-item-img" :src="item.img" :data-url="item.img" lazy />
                      <div class="detail-item-areaName">
                        <p v-for="(item ,index) in splitBatName(item.ticketName)" :key="index">{{item}}</p>
                      </div>
                    </div>
                    <span class="detail-item-productName">{{item.content}}</span>
                  </div>
                  <div class="detail-item-box" v-else v-show="active === 2 && item.activeId === 1">
                    <div class="detail-item" id="detail-item">
                      <img class="detail-item-img" :src="item.img" :data-url="item.img" lazy />
                      <div class="detail-item-areaName">
                        <p v-for="(item ,index) in splitBatName(item.ticketName)" :key="index">{{item}}</p>
                      </div>
                    </div>
                    <span class="detail-item-productName">{{item.content}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class=" col-md-12 detail-item-attentionBox">
            <p class="detail-item-attention">Notes</p>
            <p class="detail-item-attention2">*The validity period of ALL PASS is indicated in the title,regardless of the validity of the area pass. </p>
            <p class="detail-item-attention2">*Please make sure you redeem your Haruka one-way ticket and/or area pass before your All Pass expires. </p>
            <p class="detail-item-attention2">*If you do not redeem them before the expiry date they will be invalid.
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- 弹出 -->
    <div class="backdrop" v-if='showDialog'>
      <div class="backdrop-content">
        <img class="glyphicon" :src="close" alt="" @click="close2()">
        <div class="backdrop-swiper">
          <el-carousel class="banner" trigger="click" height="281px" :interval="3000">
            <el-carousel-item v-for="(item,index) in ticketDetials.tickeImg" :key="index">
              <img class="backdrop-content-img" :src="item" lazy />
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="backdrop-content-box">
          <p class="travel-copyright" v-if="ticketDetials.copyright">{{ticketDetials.copyright}}</p>
          <p class="travel-facilityName" :style="{marginTop:ticketDetials.id===21?'0px':'16px'}">
            {{ticketDetials.areaNAme}}</p>
          <p class="content-name" v-for="(item ,index) in splitBatName(ticketDetials.ticketName)" :key="index">{{item}}
          <p class="content-name" v-for="(item ,index) in splitBatName(ticketDetials.ticketName2)" :key="index">{{item}}
          </p>
          <div class="backdrop-travel-cartBox">
            <img class="backdrop-travel-cart" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/facility_area.png" lazy />
            <div class="" style="display: flex;flex-direction: column;">
              <span class="backdrop-travel-cartName"><a :href="ticketDetials.Http" target=_blank>{{ticketDetials.Http}}</a></span>
            </div>
            <div class="" style="display: flex;flex-direction: column;" v-if="ticketDetials.Http3">
              <span class="backdrop-travel-cartName"><a href="/public/gurumeguri_map2022.pdf" target=_blank>{{ticketDetials.Http4}}</a></span>
              <span class="backdrop-travel-cartName"><a href="/public/gurumeguri2022.pdf" target=_blank>{{ticketDetials.Http3}}</a></span>
            </div>
            <span class="backdrop-travel-cartName" style="margin-left: 0;color: #000;">{{ticketDetials.Http2}}</span>
          </div>
          <div class="backdrop-travel-cartBox">
            <img class="backdrop-travel-cart" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/travel-facilities.png" alt="">
            <div>
              <p class="backdrop-travel-cartName" v-for="(item ,index) in splitBatName(ticketDetials.Faci)" :key="index">{{item}}</p>
            </div>
          </div>
          <div class="backdrop-travel-cartBox">
            <img class="backdrop-travel-cart" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/travel-cart.png" alt="">
            <div>
              <p class="backdrop-travel-cartName" v-for="(item ,index) in splitBatName(ticketDetials.Doer)" :key="index">{{item}}</p>
            </div>
          </div>
          <div class="backdrop-travel-cartBox">
            <img class="backdrop-travel-cart" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/travel-name.png" alt="">
            <div>
              <p class="backdrop-travel-cartName" v-for="(item ,index) in splitBatName(ticketDetials.time)" :key="index">{{item}}</p>
            </div>
          </div>
          <div class="backdrop-travel-cartBox">
            <img class="backdrop-travel-cart" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/travel-calendar.png" alt="">
            <div>
              <p class="backdrop-travel-cartName" v-for="(item ,index) in splitBatName(ticketDetials.Calendar)" :key="index">{{item}}
              </p>
            </div>
          </div>
          <p class="backdrop-travel-cartBox">
            <img class="backdrop-travel-cart" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/travel-price.png" alt="">
            <span class="backdrop-travel-cartName">￥{{ticketDetials.price}}</span>
          </p>
          <div class="backdrop-travel-cartBox" v-if="ticketDetials.content2">
            <div>
              <p class="backdrop-travel-cartName" v-for="(item ,index) in splitBatName(ticketDetials.content2)" :key="index">{{item}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isShow: false,
      PC_home: require("@/assets/images/allpass/PC-home.png"),
      PC_line: require("@/assets/images/allpass/PC_line.png"),
      ticket_zhu2: require("@/assets/images/allpass/tikect_en1.png"),
      ticket_zhu: require("@/assets/images/allpass/tikect_en2.png"),
      close: require("@/assets/images/allpass/close.png"),
      rid_copy: require("@/assets/images/allpass/copyright.png"),
      loadImg: require("@/assets/images/allpass/loading.png"),
      active: 1,
      animateTime: 3000, // 要和 css 的过渡一致
      activeAreaName: 1,
      ticketDescribe: "Ticket Valid  ( 29 facilities)",
      planName: ["Kansai Wide-Area Pass", "5 Days Plan"],
      planName_small: ["Kansai Wide-Area Pass", "5 Days Plan"],
      planPrice: "27,700",
      planName2: ["Kansai-Hiroshima Area Pass", "5 Days Plan"],
      planName2_small: ["Kansai-Hiroshima Area Pass", "5 Days Plan"],
      planPrice2: "32,000",
      planName3: "JR-WEST All Area Pass",
      planPrice3: "39,000",
      ticketDetials: {},
      showDialog: false,
      drawer: false,
      innerDrawer: false,
      startX: "",
      startY: "",
      moveY: "",
      moveX: "",
      startTime: "",
      activeArr: [1],
      activeAreaArr: [],
      ticketArr: [
        {
          id: 1,
          type: 1,
          fontcolor: "#F98304",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/travel-12.png",
          ticketName: "EXPLORE WEST JAPAN ALL PASS",
          validityDay: 4,
          price: "21,000~",
          describe: [
            "JR Ticket + ",
            "Ticket valid at 29 tourism facilities",
            "+ Universal Studios Japan",
            "1 Day Studio Pass",
          ],
        },
        {
          id: 2,
          type: 2,
          fontcolor: "#F4B22D",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/travel-22.png",
          ticketName: "EXPLORE KANSAI AREA ALL PASS",
          validityDay: 3,
          price: "16,000~",
          describe: [
            "JR Ticket + ",
            "Ticket valid at 16 tourism facilities",
            "+ Universal Studios Japan",
            "1 Day Studio Pass",
          ],
        },
      ],
      bannerImg: [
        require("@/assets/images/allpass/pc_banner.png"),
        require("@/assets/images/allpass/PC_EN1.png"),
      ],
      bannerMb: [
        "https://qbcdn.jtrchina.com/tripelletexploreallpass/banner/mb_banner.png",
        "../img/MB_CH.png",
      ],
      /*
                西日本数组
                areaType 1京都 2大阪 3滋賀・兵庫・和歌山 4山陽山陰 5石川
                */
      scenicSpotArr: [
        // 京都
        {
          id: 1,
          areaType: 1,
          activeId: 1,
          areaNAme: "KYOTO",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/jingdu_A.jpg",
          ticketName: ["Kyoto Railway Museum Admission Ticket"],
          ticketName3: ["Kyoto Railway Museum Admission Ticket"],
          ticketName4: ["Kyoto Railway Museum Admission Ticket"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/jingdu_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/jingdu_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/jingdu_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/jingdu_D.jpg",
          ],
          Doer: ["2 min walk from Umekoji-Kyotonishi Station"],
          time: ["10:00 AM ~ 17:30 PM", "(Last entry 16:30 PM)"],
          Http: "http://www.kyotorailwaymuseum.jp/en/",
          Faci: [
            "Kankijicho, Shimogyo-ku, Kyoto",
            "(Kyoto Railway Museum Ticket Center)",
          ],
          Faci2: [
            "Kankijicho, Shimogyo-ku, Kyoto",
            "(Kyoto Railway Museum Ticket Center)",
          ],
          Calendar: [
            "Wed., New Years",
            "※It may be temporarily closed,",
            "please check the latest",
            "information on the official website in advance.",
          ],
          Calendar2: [
            "Wed., New Years",
            "※It may be temporarily closed,",
            "please check the latest",
            "information on the official website in advance.",
          ],
          price: "1,200",
        },
        {
          id: 2,
          areaType: 1,
          activeId: 1,
          areaNAme: "KYOTO",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/dongyin_A.jpg",
          ticketName: ["Toei Kyoto Studio Park Admission Ticket "],
          ticketName3: ["Toei Kyoto Studio Park Admission Ticket "],
          ticketName4: ["Toei Kyoto Studio Park Admission Ticket "],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/dongyin_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/dongyin_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/dongyin_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/dongyin_D.jpg",
          ],
          Doer: ["13 min walk from JR Hanazono Station"],
          time: [
            "10:00 AM ～ 17:00 PM",
            "※It may be temporarily closed, please check the latest",
            "information on the official website in advance.",
          ],
          Http: "http://www.toei-eigamura.com/global/en/",
          Faci: [
            "10 Uzumasa Higashihachiokacho,",
            "Ukyo-ku Kyoto-shi (Ticket Center)",
          ],
          Faci2: [
            "10 Uzumasa Higashihachiokacho,",
            "Ukyo-ku Kyoto-shi (Ticket Center)",
          ],
          Calendar: ["※Please refer to the official website"],
          Calendar2: ["※Please refer to the official website"],
          price: "2,400",
        },
        {
          id: 3,
          areaType: 1,
          activeId: 1,
          areaNAme: "KYOTO",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/lanshan_A.jpg",
          ticketName: ["Arashiyama Gourmet Coupon+Randen", "1 Day Free Ticket"],
          ticketName3: [
            "Arashiyama Gourmet Coupon+Randen",
            "1 Day Free Ticket",
          ],
          ticketName4: [
            "Arashiyama Gourmet Coupon+Randen",
            "1 Day Free Ticket",
          ],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/lanshan_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/lanshan_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/lanshan_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/lanshan_D.jpg",
          ],
          Doer: ["8 min walk from JR Saga-Arashiyama Station"],
          time: [
            'Randen "Arashiyama station information"',
            "9:00 AM ~ 18:00 PM",
          ],
          Http4: "https://www.tripellet.com/gurumeguri_map2022.pdf",
          Http3: "https://www.tripellet.com/gurumeguri2022.pdf",
          Faci: [
            "20-2 Sagatenryuji Tsukurimichicho, Ukyo Ward",
            "Kyoto (Arashiyama Station Information Center)",
          ],
          Faci2: [
            "20-2 Sagatenryuji",
            "Tsukurimichicho, Ukyo Ward",
            "Kyoto (Randen Arashiyama Eki",
            "Information Center)",
          ],
          Calendar: [
            "※Please refer to the coupon for",
            "the business hours of each branch.",
          ],
          Calendar2: [
            "※Please refer to the coupon for",
            "the business hours of each branch.",
          ],
          price: "1,000",
        },
        {
          id: 4,
          activeId: 1,
          areaType: 1,
          areaNAme: "KYOTO",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/Sky Hop Bus_A.jpg",
          ticketName: ["Kyoto Sky Hop Bus", "1 Day Ticket"],
          ticketName3: ["Kyoto Sky Hop Bus", "1 Day Ticket"],
          ticketName4: ["Kyoto Sky Hop Bus", "1 Day Ticket"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/Sky Hop Bus_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/Sky Hop Bus_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/Sky Hop Bus_C.jpg",
          ],
          Doer: ["1 min walk from JR Kyoto Station (Karasuma exit)"],
          time: ["Read more on the web (temporarily no service sometimes)"],
          Http: "https://skyhopbus.com/kyoto/",
          Faci: [
            "Higashishiokojicho Karasumadori Shiokoji Sagaru,",
            "Shimogyo-ku Kyoto Sightseeing Bus Terminal (Karasumaguchi)",
          ],
          Faci2: [
            "Higashishiokojicho Karasumadori Shiokoji Sagaru,",
            "Shimogyo-ku Kyoto Sightseeing Bus Terminal (Karasumaguchi)",
          ],
          Calendar: ["※Please refer to the official website"],
          Calendar2: ["※Please refer to the official website"],
          price: "2,500",
        },
        {
          id: 5,
          activeId: 1,
          areaType: 1,
          areaNAme: "KYOTO",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/hefu_A.jpg",
          ticketName: ["Kyoto Kimono Rental ", "Yumeyakata Gojo Shop"],
          ticketName3: ["Kyoto Kimono Rental ", "Yumeyakata Gojo Shop"],
          ticketName4: ["Kyoto Kimono Rental ", "Yumeyakata Gojo Shop"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/hefu_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/hefu_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/hefu_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/hefu_D.jpg",
          ],
          Doer: [
            "From JR Kyoto Station take the Karasuma subway line to Gojo Station, then 3 min walk",
          ],
          time: ["10:00 AM ～ 17:30 PM (Last entry 16:00 PM)"],
          Http: "https://www.en-kyoto.yumeyakata.com/",
          Faci: ["353 Shiogamacho, Shimogyo Ward, Kyoto"],
          Faci2: ["353 Shiogamacho, Shimogyo Ward, Kyoto"],
          Calendar: ["Year-end and new year (12/28 ～ 1/3)"],
          Calendar2: ["Year-end and new year (12/28 ～ 1/3)"],
          price: "3,850",
        },
        {
          id: 6,
          areaType: 1,
          areaNAme: "KYOTO",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/tianqiao_A.jpg",
          ticketName: ["Amanohashidate Ine Tourist", "2 Day Ticket"],
          ticketName2: ["(Bus・Boat・Cable Car)"],
          ticketName3: [
            "Amanohashidate Ine Tourist 2 Day Ticket",
            "(Bus・Boat・Cable Car)",
          ],
          ticketName4: [
            "Amanohashidate Ine Tourist 2 Day Ticket",
            "(Bus・Boat・Cable Car)",
          ],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/tianqiao_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/tianqiao_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/tianqiao_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/tianqiao_D.jpg",
          ],
          Doer: ["Inside Amanohashidate Station on the Kyoto Tango Railway"],
          time: [
            "9:00 AM ~ 16:30 PM",
            "(Amanohashidate Terminal Center",
            "Tourist Information Center)",
          ],
          Http: "https://www.tankai.jp/tourist_tickets/",
          Faci: [
            "Miyazu City, Kyoto Prefecture,",
            "314-2 (Amanohashidate",
            "Tourism Association Center)",
          ],
          Faci2: [
            "Miyazu City, Kyoto Prefecture,314-2 (Amanohashidate Tourism Association Center)",
          ],
          Calendar: ["Open all year"],
          Calendar2: ["Open all year"],
          price: "2,600",
        },
        // 大阪
        {
          id: 7,
          activeId: 1,
          areaType: 2,
          areaNAme: "OSAKA",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/meitian_A.jpg",
          ticketName: [
            "Umeda Sky Building Kuchu Teien Observatory",
            "Admission Ticket",
          ],
          ticketName3: [
            "Umeda Sky Building Kuchu Teien Observatory Admission Ticket",
          ],
          ticketName4: [
            "Umeda Sky Building Kuchu Teien Observatory Admission Ticket",
          ],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/meitian_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/meitian_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/meitian_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/meitian_D.jpg",
          ],
          Doer: ["7 min walk from JR Osaka Station"],
          time: ["9:30 AM ~ 10:30 PM ", "(Last entry 10:00 PM)"],
          Http: "https://www.skybldg.co.jp/en/",
          Faci: [
            "Umeda Sky Building, 1-1-88 Oyodonaka,",
            "Kita Ward, Osaka (39F Ticket Counter)",
          ],
          Faci2: [
            "Umeda Sky Building, 1-1-88 Oyodonaka, Kita Ward, Osaka",
            "(39F Ticket Counter)",
          ],
          Calendar: ["Open all year"],
          Calendar2: ["Open all year"],
          price: "1,500",
        },
        {
          id: 8,
          activeId: 1,
          areaType: 2,
          areaNAme: "OSAKA",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/HARUKAS_A.jpg",
          ticketName: ["ABENO HARUKAS", "Admission Ticket"],
          ticketName3: ["ABENO HARUKAS Admission Ticket"],
          ticketName4: ["ABENO HARUKAS Admission Ticket"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/HARUKAS_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/HARUKAS_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/HARUKAS_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/HARUKAS_D.jpg",
          ],
          Doer: ["Near JR Tennoji Station"],
          time: ["9:00 AM ~ 10:00 PM ", "(Last entry 7:30 PM)"],
          Http: "https://www.abenoharukas-300.jp/en/",
          Faci: ["16F, 1-1-43Abenosuji, Abeno-ku, Osaka, Japan"],
          Faci2: ["16F, 1-1-43Abenosuji, Abeno-ku, Osaka, Japan"],
          Calendar: ["Open all year"],
          Calendar2: ["Open all year"],
          price: "1,500",
        },
        {
          id: 9,
          activeId: 1,
          areaType: 2,
          areaNAme: "OSAKA",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/gelan_A1.jpg",
          ticketName: ["Hotel Granvia Osaka", "Tea Lounge", "Dessert Set"],
          ticketName3: ["Hotel Granvia Osaka", "Tea Lounge Dessert Set"],
          ticketName4: ["Hotel Granvia Osaka", "Tea Lounge Dessert Set"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/gelan_A1.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/gelan_B1.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/gelan_C1.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/gelan_D1.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/gelan_E1.jpg",
          ],
          Doer: ["Connected to JR Osaka Station  Central Count"],
          time: [
            "Dessert Serving Time ：11:00 AM ~ 16:00 PM",
            "Weekday ： 10:00 AM ~ 20:00 PM（Lo19:30 PM）",
            "Saturday：9:30 AM ~ 20:00 PM（Lo19:30 PM）",
            "Sunday / Holiday：9:30 AM ~ 18:00 PM（Lo17:30 PM）",
          ],
          Http: "https://www.hotelgranviaosaka.jp/en/dining/lobby-lounge/",
          Faci: ["3-1-1 Umeda, Kita-ku, Osaka", "(1F Tea Lounge)"],
          Faci2: ["3-1-1 Umeda, Kita-ku, Osaka", "(1F Tea Lounge)"],
          Calendar: ["Open all year"],
          Calendar2: ["Open all year"],
          price: "2,300",
          content: "",
          content2: [
            "※ The images are for references only.",
            "※ Meal contents vary seasonally.",
          ],
        },
        {
          id: 10,
          activeId: 1,
          areaType: 2,
          areaNAme: "OSAKA",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/bashi_A.jpg",
          ticketName: ["Osaka Aqua Bus"],
          ticketName3: ["Osaka Aqua Bus"],
          ticketName4: ["Osaka Aqua Bus"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/bashi_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/bashi_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/bashi_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/bashi_D.jpg",
          ],
          Doer: [
            "3-minute walk from JR Osaka",
            "Loop Line Osakajo-koen Station",
          ],
          time: [
            "11:00 AM / 11:45 AM / 12:30 AM",
            "13:15 PM / 14:00 PM / 14:45 PM /",
            "15:30 PM / 16:15 PM",
          ],
          price: "1,600",
          Http: " http://suijo-bus.osaka",
          Faci: [
            "Osakajo, Chuo-ku, Osaka City",
            "(Osaka Aqua Bus stop Osaka Castle Port",
            "boarding ticket counter)",
          ],
          Faci2: [
            "Osakajo, Chuo-ku, Osaka City",
            "(Osaka Aqua Bus stop Osaka Castle Port boarding ticket counter)",
          ],
          Calendar: [
            "Non-Operating Hours: 2 days of weekdays",
            "/ Afternoon on July 25th / Mid-January",
            "/ Early February",
          ],
          Calendar2: [
            "Non-Operating Hours:",
            "2 days of weekdays",
            "/ Afternoon on July 25th / Mid-January",
            "/Early February",
          ],
          content: "",
          content2: [
            "※Special prices are available in spring",
            "(Late March to early April).",
          ],
        },
        {
          id: 11,
          activeId: 1,
          areaType: 2,
          areaNAme: "OSAKA",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/daban_A.jpg",
          ticketName: ["Osaka Museum of", "Housing and Living"],
          ticketName3: ["Osaka Museum of Housing and Living"],
          ticketName4: ["Osaka Museum of Housing and Living"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/daban_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/daban_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/daban_C.jpg",
          ],
          Doer: ["7 min walk from JR Tenma Station"],
          time: ["10:00 AM ~ 17:00 PM", "(Last entry 4:30 PM)"],
          Http: "http://konjyakukan.com/",
          Faci: [
            "6-Chome 4-20, Tenjinbashi, Kita-ku,",
            "Osaka-shi 8F of residence",
            "information center building",
            "(Ticket Center)",
          ],
          Faci2: [
            "6-Chome 4-20, Tenjinbashi, Kita-ku,",
            "Osaka-shi 8F of residence",
            "information center building",
            "(Ticket Center)",
          ],
          Calendar: ["Tuesday．Year-end and new year (12/28 ～ 1/4)"],
          Calendar2: ["Tuesday．Year-end and new year", "(12/28 ～ 1/4)"],
          price: "400",
        },
        // 滋賀・兵庫・和歌山
        {
          id: 12,
          activeId: 1,
          areaType: 3,
          areaNAme: "HYOGO",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/shenhu_A1.jpg",
          ticketName: ["Kobe Nunobiki Herb Gardens", "& Ropeway Ticket"],
          ticketName3: ["Kobe Nunobiki Herb Gardens & Ropeway Ticket"],
          ticketName4: ["Kobe Nunobiki Herb Gardens & Ropeway Ticket"],
          Doer: ["5 min walk from JR Shin-Kobe Station"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/shenhu_A1.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/shenhu_B1.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/shenhu_C1.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/shenhu_D1.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/shenhu_E1.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/shenhu_F1.jpg",
          ],
          time: ["(Sanroku Station 4F Information)", "9:30 AM ~ 16:45 PM"],
          Http: "https://www.kobeherb.com/en/",
          Faci: [
            "1-4-3 Kitanocho, Chuo-ku, Kobe, Hyogo",
            "(4F Information Center of Sanroku Station)",
          ],
          Faci2: [
            "1-4-3 Kitanocho, Chuo-ku, Kobe, Hyogo (4F Information Center of ",
            "Sanroku Station)",
          ],
          Calendar: ["Please refer to the official website"],
          Calendar2: ["Please refer to the official website"],
          price: "1,800",
        },
        {
          id: 13,
          activeId: 1,
          areaType: 3,
          areaNAme: "HYOGO",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/mingshi_A.jpg",
          ticketName: ["「WAZANOIPPINN」Akashi", "Sushi Coupon of JYP2000"],
          ticketName3: ["「WAZANOIPPINN」Akashi Sushi Coupon of JYP2000"],
          ticketName4: ["「WAZANOIPPINN」Akashi Sushi Coupon of JYP2000"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/mingshi_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/mingshi_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/mingshi_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/mingshi_D.jpg",
          ],
          Doer: ["near JR Akashi Station"],
          time: [
            "(weekdays) 9:00 AM ~ 19:00 PM",
            "(weekends, holidays) 9:00 AM ~ 18:00 PM",
          ],
          Http: "https://www.yokoso-akashi.jp/sushi_coupon",
          Faci: [
            "1-1-23 Oakashicho Akashi, Hyogo (Akashi Tourist Information Center at Piole West Hall)",
          ],
          Faci2: [
            "1-1-23 Oakashicho Akashi, Hyogo (Akashi Tourist Information Center at Piole West Hall)",
          ],
          Calendar: ["Year-end and New year (12/31 ～ 1/1)"],
          Calendar2: ["Year-end and New year", "(12/31 ～ 1/1)"],
          price: "2,000",
        },
        {
          id: 14,
          activeId: 1,
          areaType: 3,
          areaNAme: "HYOGO",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/kexueguan_A.jpg",
          ticketName: [
            "Hyogo Prefecture Akashi Municipal Planetarium Admission Ticket",
          ],
          ticketName3: [
            "Hyogo Prefecture Akashi Municipal Planetarium Admission Ticket",
          ],
          ticketName4: [
            "Hyogo Prefecture Akashi Municipal Planetarium Admission Ticket",
          ],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/kexueguan_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/kexueguan_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/kexueguan_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/kexueguan_D.jpg",
          ],
          Doer: [
            "15 min walk from JR Akashi Station,",
            "3 min walk from Hitomarumae",
            "Station on Sanyo Electric Railway",
          ],
          time: [
            "Akashi Information Center",
            "(Weekdays) 9:00 AM ~ 19:00 PM",
            "(Weekends and holidays)",
            "9:00 AM ~ 18:00 PM",
            "Akashi Municipal Planetarium :",
            "9:30 AM ~ 17:00 PM",
            "(Doors close at 16:30 PM)",
          ],
          Http: "https://www.am12.jp/",
          Faci: [
            "Exchange location： Akashi City, Hyogo",
            "Prefecture1-1-23",
            "Oakashicho Piole Akashi West Building",
            "Facility address：2-6 Hitomarucho, Akashi City,",
            "Hyogo Prefecture",
          ],
          Faci2: [
            "Exchange location： Akashi City, Hyogo",
            "Prefecture1-1-23",
            "Oakashicho Piole Akashi West Building",
            "Facility address：2-6 Hitomarucho, Akashi City,",
            "Hyogo Prefecture",
          ],
          Calendar: [
            "year-end and new year holidays",
            "※It may be temporarily closed,",
            "please check the latest",
            "information on the official website",
            "in advance.",
          ],
          Calendar2: [
            "year-end and new year holidays",
            "※It may be temporarily closed,",
            "please check the latest",
            "information on the official website",
            "in advance.",
          ],
          price: "700",
        },
        {
          id: 15,
          areaType: 3,
          areaNAme: "HYOGO",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/JENOVA_A.jpg",
          ticketName: [
            "Awaji Jenova Line Ferry",
            "One Way Ticket",
            "(Akashi to Iwaya)",
          ],
          ticketName3: [
            "Awaji Jenova Line Ferry One Way Ticket",
            "(Akashi to Iwaya)",
          ],
          ticketName4: [
            "Awaji Jenova Line Ferry One Way Ticket",
            "(Akashi to Iwaya)",
          ],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/JENOVA_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/JENOVA_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/JENOVA_C.jpg",
          ],
          Doer: ["8 min walk from JR Akashi Station"],
          time: [
            "(weekdays)",
            "Akashi Cruising Boarding Time：",
            "9:00 AM ～ 17:00 PM",
            "Iwaya Cruising Boarding Time：",
            "9:00 AM ～ 17:00 PM",
            "(Saturday・Sunday・Holiday)",
            "Akashi Cruising Boarding Time：",
            "9:00 AM ～ 17:00 PM",
            "Iwaya Cruising Boarding Time：",
            "9:00 AM ～ 17:00 PM",
            "※Please check the official website",
            "for the detailed timetable.",
          ],
          Http: "https://www.am12.jp/",
          Faci: [
            "Akashi Cruise Boarding Location：",
            "2-Chome-10 Honmachi,",
            "Akashi, Hyogo Prefecture",
            "Iwaya Boarding Location：",
            "925-27Iwaya, Awaji, Hyogo Prefecture",
            "※JENOVA Cruise allows passengers to",
            "board on either side of the ship.",
          ],
          Faci2: [
            "Akashi Cruise Boarding Location：",
            "2-Chome-10 Honmachi,",
            "Akashi, Hyogo Prefecture",
            "Iwaya Boarding Location：",
            "925-27Iwaya, Awaji, Hyogo Prefecture",
            "※JENOVA Cruise allows passengers to",
            "board on either side of the ship.",
          ],
          Calendar: ["Operations may be suspended due", "to bad weather"],
          Calendar2: ["Operations may be suspended due", "to bad weather"],
          price: "530",
        },
        {
          id: 16,
          activeId: 1,
          areaType: 3,
          areaNAme: "SHIGA",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/changbin_A.jpg",
          ticketName: ["Nagahama Roman Passport "],
          ticketName3: ["Nagahama Roman Passport "],
          ticketName4: ["Nagahama Roman Passport "],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/changbin_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/changbin_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/changbin_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/changbin_D.jpg",
          ],
          Doer: ["inside JR Nagahama Station"],
          time: ["Nagahama Tourist Information Center 9:15 AM ~ 16:45 PM"],
          Http: "https://kitabiwako.jp/spot/spot_8966",
          Faci: [
            "1-5 Kitafunacho, Nagahama, Shiga, inside JR Nagahama Station",
          ],
          Faci2: [
            "1-5 Kitafunacho, Nagahama, Shiga,",
            "inside JR Nagahama Station",
          ],
          Calendar: ["New Years"],
          Calendar2: ["New Years"],
          price: "1,200",
        },
        {
          id: 17,
          activeId: 1,
          areaType: 3,
          areaNAme: "SHIGA",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/dajin_A.jpg",
          ticketName: [" Otsu Prince Hotel 37F Lake View Dining 'Biona' Lunch"],
          ticketName3: [
            " Otsu Prince Hotel 37F Lake View Dining 'Biona' Lunch",
          ],
          ticketName4: [
            " Otsu Prince Hotel 37F Lake View Dining 'Biona' Lunch",
          ],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/dajin_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/dajin_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/dajin_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/dajin_D.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/dajin_E.jpg",
          ],
          Doer: ["10 min free shuttle bus from JR Otsu Station"],
          time: [
            "Business Hours:：11:30 AM ～ 15:00 PM",
            "Dining time： 11:30 AM ～ 13:00 PM",
            "13:30 AM ～ 15:00 PM",
            "(Dining time limit 90 min,2 time slots)",
          ],
          Http: "https://www.princehotels.com/otsu/restaurants/lake-view-dining-biona/?_ga=2.154385208.1819375824.1598513837-2140798614.1596609460",
          Faci: [
            "4-7-7, Nionohama, Otsu City, Shiga, Japan  Otsu Prince Hotel (37F Lake View Dining Biona)",
          ],
          Faci2: [
            "4-7-7, Nionohama, Otsu City, Shiga, Japan  Otsu Prince Hotel",
            "(37F Lake View Dining Biona)",
          ],
          Calendar: ["Please see the website for operating days"],
          Calendar2: ["Please see the website for operating days"],
          price: "3,000",
        },
        {
          id: 18,
          activeId: 1,
          areaType: 3,
          areaNAme: "SHIGA",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/pipahu_A.jpg",
          ticketName: [
            "Chikubushima Island Cruise (Hikone Port – Chikubushima",
          ],
          ticketName2: ["* Round Trip)"],
          ticketName3: [
            "Chikubushima Island Cruise (Hikone Port – Chikubushima",
            "* Round Trip)",
          ],
          ticketName4: [
            "Chikubushima Island Cruise (Hikone Port – Chikubushima * Round Trip)",
          ],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/pipahu_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/pipahu_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/pipahu_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/pipahu_D.jpg",
          ],
          Doer: [
            "Approx. 8 minutes from JR Hikone Station by free shuttle bus, get off at the Hikone port stop",
          ],
          time: ["weekends and holidays"],
          Http: "http://www.ohmitetudo.co.jp/marine/",
          Faci: ["3755, Matsubaracho, Hikone-shi, Shiga (Hikone Port)"],
          Faci2: ["3755, Matsubaracho, Hikone-shi, Shiga (Hikone Port)"],
          Calendar: ["Closed：weekdays, 12/30 ～ 12/31"],
          Calendar2: ["Closed：weekdays, 12/30 ～ 12/31"],
          price: "3,000",
        },
        {
          id: 19,
          areaType: 3,
          areaNAme: "SHIGA",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/bapanshan_A.jpg",
          ticketName: ["Hachimanyama Ropeway", "Round-Trip Ticket "],
          ticketName3: ["Hachimanyama Ropeway", "Round-Trip Ticket "],
          ticketName4: ["Hachimanyama Ropeway", "Round-Trip Ticket "],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/bapanshan_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/bapanshan_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/bapanshan_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/bapanshan_D.jpg",
          ],
          Doer: [
            "7 min on the bus bound for Chomeiji Temple leaving from boarding point 6 at the north exit of JR Omihachiman Station, get off at the Osugi-cho Hachimanyama Ropeway Entrance stop",
          ],
          time: ["9:00 AM ~ 5:00 PM", "*Last return trip at 4:30 PM"],
          Http: "http://www.ohmitetudo.co.jp/hachimanyama/open-price/index.html/",
          Faci: ["Miyauchicho, Omihachiman-shi, Shiga (Ticket Counter)"],
          Faci2: ["Miyauchicho, Omihachiman-shi, Shiga (Ticket Counter)"],
          Calendar: ["Open all year"],
          Calendar2: ["Open all year"],
          price: "890",
        },
        {
          id: 20,
          areaType: 3,
          areaNAme: "WAKAYAMA",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/chuanben_A.jpg",
          ticketName: [
            "Kushimoto Marine Park Aquarium & Undersea Observation Tower",
            "Admission Ticket",
          ],
          ticketName3: [
            "Kushimoto Marine Park Aquarium & Undersea Observation Tower Admission Ticket",
          ],
          ticketName4: [
            "Kushimoto Marine Park Aquarium & Undersea Observation Tower Admission Ticket",
          ],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/chuanben_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/chuanben_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/chuanben_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/chuanben_D.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/chuanben_E.jpg",
          ],
          Doer: ["12 min by free shuttle bus from JR Kushimoto Station"],
          time: ["9:00 AM ~ 4:30 PM (Last entry 4:00 PM)"],
          Http: "http://www.kushimoto.co.jp/english/",
          Faci: [
            "1157 Arida, Kushimoto, Higashimuro District, Wakayama (Ticket Counter)",
          ],
          Faci2: [
            "1157 Arida, Kushimoto, Higashimuro District, Wakayama (Ticket Counter)",
          ],
          Calendar: ["Open all year"],
          Calendar2: ["Open all year"],
          price: "1,800",
        },
        {
          id: 21,
          areaType: 3,
          areaNAme: "WAKAYAMA",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/zhichuan_A.jpg",
          copyright: "「DESIGNED BY EIJI MITOOKA+DON DESIGN ASSOCIATES」",
          ticketName: ["Wakayama Electric Railway Kishigawa Line 1 Day Ticket"],
          ticketName3: [
            "Wakayama Electric Railway Kishigawa Line",
            "1 Day Ticket",
          ],
          ticketName4: [
            "Wakayama Electric Railway Kishigawa Line",
            "1 Day Ticket",
          ],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/zhichuan_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/zhichuan_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/zhichuan_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/zhichuan_D.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/zhichuan_E.jpg",
          ],
          Doer: ["JR Wakayama Station, Platform 9"],
          time: ["Varies between types of train"],
          Http: "https://www.wakayama-dentetsu.co.jp/en/",
          Faci: [
            "5 Chome 2 Misonocho, Wakayama (Wakayama Electric Railway Wakayama Station)",
          ],
          Faci2: [
            "5 Chome 2 Misonocho, Wakayama (Wakayama Electric Railway Wakayama Station)",
          ],
          Calendar: [
            "Please see the website for operating days and",
            "the Station Master Cat's work schedule.",
          ],
          Calendar2: [
            "Please see the website for operating days and the Station Master Cat's work schedule.",
          ],
          price: "800",
        },
        // 山陽山陰
        {
          id: 22,
          areaType: 4,
          areaNAme: "OKAYAMA",
          img: require("@/assets/images/allpass/erdao_A.jpg"),
          tickeImg: [
            require("@/assets/images/allpass/erdao_A.jpg"),
            require("@/assets/images/allpass/erdao_B.jpg"),
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/erdao_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/erdao_D.jpg",
          ],
          ticketName: [
            "Kojima Tour Bus",
            " 1-Day Pass and ",
            "Denim Handmade Experience",
          ],
          ticketName3: [
            "Kojima Tour Bus",
            " 1-Day Pass and ",
            "Denim Handmade Experience",
          ],
          ticketName4: [
            "Kojima Tour Bus",
            " 1-Day Pass and ",
            "Denim Handmade Experience",
          ],
          Doer: [
            "12 mi by bus from JR Kojima Station (exchange at)",
            "JR Kojima Station ticket gates",
          ],
          time: [
            "Exchange: 9:00 AM ~ 17:00 PM",
            "Business Hours:",
            "Denim Handmade Experience:",
            "9:00 AM ~ 18:00 PM",
            "Experience Session:",
            "11:00 AM, 14:00 PM",
            "FUREAI bus / TOKOHAI Bus: Everyday",
            "Kojima Tour Bus: Every Friday, Weekend, and Holiday",
          ],
          Http: "http://shimoden.net/rosen/kikaku/jeans.html",
          Faci: [
            "1 Chome Kojima Ekimae,",
            "Kurashiki, Okayama",
            "(JR Kojima Station boarding place)",
          ],
          Faci2: [
            "1 Chome Kojima Ekimae,",
            "Kurashiki, Okayama (JR Kojima Station boarding place)",
          ],
          Calendar: [
            "Closed:",
            "Denim Handmade Experience:",
            "Year-end and New year",
            "Kojima Tour Bus:",
            "Monday to Thursday",
            "and Year-end and New year",
          ],
          Calendar2: [
            "Closed:",
            "Denim Handmade Experience:",
            "Year-end and New year",
            "Kojima Tour Bus:",
            "Monday to Thursday",
            "and Year-end and New year",
          ],
          price: "1,000",
        },
        {
          id: 23,
          areaType: 4,
          areaNAme: "OKAYAMA",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/gangshan_A.jpg",
          ticketName: [
            "Hotel Granvia Okayama Pasry Chef Featured Sundae & Drink",
          ],
          ticketName3: [
            "Hotel Granvia Okayama Pasry Chef Featured Sundae & Drink",
          ],
          ticketName4: [
            "Hotel Granvia Okayama Pasry Chef Featured Sundae & Drink",
          ],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/gangshan_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/gangshan_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/gangshan_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/gangshan_D.jpg",
          ],
          Doer: ["Near JR Okayama Station"],
          time: ["12:00 PM ~ 17:00 PM (Hotel 1F Lobby)"],
          Http: "https://granvia-oka.co.jp/en/restaurant/#lum",
          Faci: ["1F, 1-5 Ekimotomachi Kita-ku Okayama City (1F Lumière)"],
          Faci2: ["1F, 1-5 Ekimotomachi Kita-ku Okayama City (1F Lumière)"],
          Calendar: ["Open all year"],
          Calendar2: ["Open all year"],
          price: "2,500",
        },
        {
          id: 24,
          areaType: 4,
          areaNAme: "HIROSHIMA",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/daotian_A.jpg",
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/daotian_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/daotian_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/daotian_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/daotian_D.jpg",
          ],
          ticketName: [
            "Shimada Fisheries Oyster Farm Tour & Going Close to",
            "the Itsukushima Shrine",
          ],
          ticketName3: [
            "Shimada Fisheries Oyster Farm Tour & Going Close to the Itsukushima Shrine",
          ],
          ticketName4: [
            "Shimada Fisheries Oyster Farm Tour & Going Close to the Itsukushima Shrine",
          ],
          Doer: [
            "15 min on foot from Miyajimaguchi",
            "Station on Sanyo Main Line（about 10",
            "min walk from the cruise boarding",
            "location）",
          ],
          time: ["10:00 AM ~ 16:00 PM"],
          Http: "https://shimadasuisan.com/kakigoya-en.html",
          Faci: [
            "Miyajimaguchi Nishi 1-2-6, Hatsukaichi, Hiroshima",
            "(Ticket Counter)",
          ],
          Faci2: [
            "Miyajimaguchi Nishi 1-2-6, Hatsukaichi, Hiroshima",
            "(Ticket Counter)",
          ],
          Calendar: ["Open all year"],
          Calendar2: ["Open all year"],
          price: "2,200",
        },
        {
          id: 25,
          areaType: 4,
          areaNAme: "HIROSHIMA",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/laihu_A.jpg",
          ticketName: ["Setoda Touring Pass"],
          ticketName3: ["Setoda Touring Pass"],
          ticketName4: ["Setoda Touring Pass"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/laihu_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/laihu_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/laihu_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/laihu_A.jpg",
          ],
          Doer: ["JR Onomichi Station"],
          time: [
            "9:00 AM ~ 18:00 PM (Onomichi Station Tourist Information Center)",
          ],
          Http: "https://www.ononavi.jp/fan/onotabi/guruseto/ ",
          Faci: [
            "1-1 Higashigoshocho, Onomichi-city, Hiroshima-ken (Onomichi Station Tourist Information)",
          ],
          Faci2: [
            "1-1 Higashigoshocho, Onomichi-city, Hiroshima-ken (Onomichi Station Tourist Information)",
          ],
          Calendar: ["12/29 ～ 1/3"],
          Calendar2: ["12/29 ～ 1/3"],
          price: "4,500",
        },
        {
          id: 26,
          areaType: 4,
          areaNAme: "YAMAGUCHI",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/shankou_A.jpg",
          ticketName: ["Hofu City Sightseeing", "Tour Pass"],
          ticketName3: ["Hofu City Sightseeing", "Tour Pass"],
          ticketName4: ["Hofu City Sightseeing", "Tour Pass"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/shankou_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/shankou_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/shankou_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/shankou_D.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/shankou_E.jpg",
          ],
          Doer: ["Inside JR Hofu Station concourse"],
          time: ["9:00 AM ~ 18:00 PM (Hofu City Tourist Information Center)"],
          Http: "https://visit-hofu.jp/ticket",
          Faci: [
            "1-chome-1-1 Ebisumachi, Hofu, Yamaguchi  Hofu City Tourist Information Center (inside JR Hofu Station)",
          ],
          Faci2: [
            "1-chome-1-1 Ebisumachi, Hofu,",
            "Yamaguchi  Hofu City",
            "Tourist Information Center",
            "(inside JR Hofu Station)",
          ],
          Calendar: ["Open all year"],
          Calendar2: ["Open all year"],
          price: "1,200",
        },
        {
          id: 27,
          areaType: 4,
          areaNAme: "SHIMANE",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/youzhiyuan_A.jpg",
          ticketName: ["YUUSHIEN Garden in Daikonshima"],
          ticketName3: ["YUUSHIEN Garden in Daikonshima"],
          ticketName4: ["YUUSHIEN Garden in Daikonshima"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/youzhiyuan_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/youzhiyuan_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/youzhiyuan_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/youzhiyuan_D.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/youzhiyuan_E.jpg",
          ],
          Doer: [
            "About 50 minutes by bus. Take the bus from boarding point 6 at JR Matsue Station.",
          ],
          time: [
            "10:00 AM ~ 17:00 PM (Last entry 30 minutes before closing)",
            "*Hours may be extended",
          ],
          Http: "https://www.yuushien.com/language/en.html",
          Faci: ["1260-2, Hanyu, Yatsukacho, Matsue, Shimane"],
          Faci2: ["1260-2, Hanyu, Yatsukacho, Matsue, Shimane"],
          Calendar: ["Year-end (12/30 ～ 12/31)"],
          Calendar2: ["Year-end (12/30 ～ 12/31)"],
          price: "1,000",
        },
        // 石川
        {
          id: 28,
          areaType: 5,
          areaNAme: "ISHIKAWA",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shichuan/guozhisen_A.jpg",
          ticketName: ["Kaga Traditional Handicrafts Village Yunokuni no Mori"],
          ticketName3: [
            "Kaga Traditional Handicrafts Village Yunokuni no Mori",
          ],
          ticketName4: [
            "Kaga Traditional Handicrafts Village Yunokuni no Mori",
          ],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shichuan/guozhisen_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shichuan/guozhisen_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shichuan/guozhisen_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shichuan/guozhisen_D.jpg",
          ],
          Doer: [
            "Approx. 40 minutes on the CANBUS Mountain route from",
            "JR Kagaonsen Station",
          ],
          time: ["9:00 AM ～ 16:30 PM", "（ Last entry 15:30 PM）"],
          Http: "https://www.yunokuni.jp/mori/?lang=en",
          Faci: [
            "Na 3-3 Awazu-onsen, Komatsu City",
            "Ishikawa (Ticket Counter)",
          ],
          Faci2: [
            "Na 3-3 Awazu-onsen, Komatsu City",
            "Ishikawa (Ticket Counter)",
          ],
          Calendar: [
            "※It may be temporarily closed,",
            "please check the latest",
            "information on the official website in advance.",
          ],
          Calendar2: [
            "※It may be temporarily closed,",
            "please check the latest",
            "information on the official website in advance.",
          ],
          price: "550",
        },
        {
          id: 29,
          areaType: 5,
          areaNAme: "ISHIKAWA",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shichuan/CANBUS_A.jpg",
          ticketName: ["Kaga Tour Buss CANBUS", "1-Day Ticket"],
          ticketName3: ["Kaga Tour Buss CANBUS", "1-Day Ticket"],
          ticketName4: ["Kaga Tour Buss CANBUS", "1-Day Ticket"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shichuan/CANBUS_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shichuan/CANBUS_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shichuan/CANBUS_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shichuan/CANBUS_D.jpg",
          ],
          Doer: ["Near JR Kagaonsen Station"],
          time: ["8:30 AM ~ 18:00 PM"],
          Http: "http://www.kaga-canbus.jp/english.php",
          Faci: [
            "Ri 1-3, Sakumimachi, Kaga-shi, Ishikawa-ken",
            "(Machizukuri Ticket Counter)",
          ],
          Faci2: [
            "Ri 1-3, Sakumimachi, Kaga-shi, Ishikawa-ken (Machizukuri Ticket Counter)",
          ],
          Calendar: ["Open all year"],
          Calendar2: ["Open all year"],
          price: "1,100",
        },
      ],
      showList: [],
      md3: [
        {
          id: 1,
          name: "ALL AREA",
          num: 29,
          areaType: 0,
        },
        {
          id: 2,
          name: "OSAKA",
          areaType: 2,
        },
        {
          id: 3,
          name: "KYOTO",
          areaType: 1,
        },
      ],
      md4: [
        {
          id: 4,
          name: "ISHIKAWA",
          areaType: 5,
        },
      ],
      md5: [
        {
          id: 5,
          name: "SHIGA．HYOGO．WAKAYAMA",
          areaType: 3,
        },
      ],
      md7: [
        {
          id: 6,
          name: "SANIN．SANYO",
          areaType: 4,
        },
      ],
    };
  },
  created() {
    this.showArea();
  },
  computed: {
    lan() {
      return this.lanList[localStorage.getItem("locale")];
    },
    language() {
      return localStorage.getItem("locale") || "zh_tw";
    },
  },
  methods: {
    change(item) {
      this.active = item.id;
      if (this.active === 1) {
        this.ticketDescribe = "Ticket Valid  (29 facilities)";
        this.planName = ["Kansai Wide-Area Pass", "5 Days Plan"];
        this.planPrice = "27,700";
        this.planName2 = ["Kansai-Hiroshima Area Pass", "5 Days Plan"];
        this.planPrice2 = "32,000";
        this.planName3 = "JR-WEST All Area Pass";
        this.planPrice3 = "39,000";
        this.md3[0].num = 29;
        this.showList = this.scenicSpotArr;
        let array = {
          id: 15,
          areaType: 3,
          areaNAme: "HYOGO",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/JENOVA_A.jpg",
          ticketName: [
            "Awaji Jenova Line Ferry",
            "One Way Ticket",
            "(Akashi to Iwaya)",
          ],
          ticketName3: [
            "Awaji Jenova Line Ferry One Way Ticket",
            "(Akashi to Iwaya)",
          ],
          ticketName4: [
            "Awaji Jenova Line Ferry One Way Ticket",
            "(Akashi to Iwaya)",
          ],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/JENOVA_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/JENOVA_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/JENOVA_C.jpg",
          ],
          Doer: ["8 min walk from JR Akashi Station"],
          time: [
            "(weekdays)",
            "Akashi Cruising Boarding Time：",
            "9:00 AM ～ 17:00 PM",
            "Iwaya Cruising Boarding Time：",
            "9:00 AM ～ 17:00 PM",
            "(Saturday・Sunday・Holiday)",
            "Akashi Cruising Boarding Time：",
            "9:00 AM ～ 17:00 PM",
            "Iwaya Cruising Boarding Time：",
            "9:00 AM ～ 17:00 PM",
            "※Please check the official website",
            "for the detailed timetable.",
          ],
          Http: "https://www.am12.jp/",
          Faci: [
            "Akashi Cruise Boarding Location：",
            "2-Chome-10 Honmachi,",
            "Akashi, Hyogo Prefecture",
            "Iwaya Boarding Location：",
            "925-27Iwaya, Awaji, Hyogo Prefecture",
            "※JENOVA Cruise allows passengers to",
            "board on either side of the ship.",
          ],
          Faci2: [
            "Akashi Cruise Boarding Location：",
            "2-Chome-10 Honmachi,",
            "Akashi, Hyogo Prefecture",
            "Iwaya Boarding Location：",
            "925-27Iwaya, Awaji, Hyogo Prefecture",
            "※JENOVA Cruise allows passengers to",
            "board on either side of the ship.",
          ],
          Calendar: ["Operations may be suspended due", "to bad weather"],
          Calendar2: ["Operations may be suspended due", "to bad weather"],
          price: "530",
          content: "",
          content2: "",
        };
        this.showList.findIndex((item) => {
          if (item.id === 14) {
            return true;
          }
        });
        this.showList.splice(14, 0, array);
      } else {
        this.ticketDescribe = "Ticket Valid  (16 facilities)";
        this.planName = ["Kansai Area Pass 3 Days Plan"];
        this.planName_small = ["Kansai Area Pass 3 Days Plan"];
        this.planPrice = "19,300";
        this.planName2 = ["Kansai Wide Area Pass 5 Days"];
        this.planPrice2 = "22,700";
        this.planName2_small = ["Kansai Wide Area Pass 5 Days"];
        this.planName3 = "";
        this.planPrice3 = "";
        this.md3[0].num = 16;
        this.showList = this.scenicSpotArr;
        let id15 = this.showList.findIndex((item) => {
          if (item.id === 15) {
            return true;
          }
        });
        this.showList.splice(id15, 1);
      }
      this.activeAreaArr = []; //清除已选中的地区id
      this.activeArr = [1]; //切换探游券时 默认全部
    },
    openSmall(item) {
      this.ticketDetials = item;
      this.innerDrawer = true;
    },
    open(item) {
      this.ticketDetials = item;
      this.showDialog = true;
      this.innerDrawer = true;
      var mo = function (e) {
        e.preventDefault();
      };
      document.body.style.overflow = "hidden";
      document.addEventListener("touchmove", mo, false); // 禁止页面底部内容滑动
    },
    close2() {
      this.showDialog = false;
      var mo = function (e) {
        e.preventDefault();
      };
      document.body.style.overflow = ""; // 出现滚动条
      document.removeEventListener("touchmove", mo, false);
    },
    showArea() {
      this.showList = this.scenicSpotArr;
    },
    activeArea(item) {
      let index = 0;
      this.showList = [];
      if (item.areaType === 0) {
        // 选择了所有
        this.activeArr = [1]; //所有
        this.activeAreaArr = [];
        this.active === 1
          ? (this.showList = this.scenicSpotArr)
          : (this.showList = this.scenicSpotArr); //判断是否西日本
        return;
      }
      if (item.areaType !== 0) {
        // 没有选择全部地区并且选的是西日本
        let alldelIndex = this.checkArray(this.activeArr, 1); //找到复选地区中所有地区的下标
        alldelIndex !== -1 ? this.activeArr.splice(alldelIndex, 1) : ""; //如果有所有地区的下标就删除
        let activeArrDelIndex = this.checkArray(this.activeArr, item.id); //查找复选地区是否已经存在
        let activeAreaArrDelIndex = this.checkArray(
          this.activeAreaArr,
          item.areaType
        ); //查找复选地区是否已经存在
        activeArrDelIndex === -1
          ? this.activeArr.push(item.id)
          : this.activeArr.splice(activeArrDelIndex, 1); //如果目前地区存在就删除，不然就加入
        activeAreaArrDelIndex === -1
          ? this.activeAreaArr.push(item.areaType)
          : this.activeAreaArr.splice(activeAreaArrDelIndex, 1); //查找复选地区是否已经存在
        this.scenicSpotArr.forEach((item1) => {
          if (item.areaType === item1.areaType) {
            // 去重
            let newArr = [];
            for (let i = 0; i < this.activeArr.length; i++) {
              newArr.indexOf(this.activeArr[i]) == -1
                ? newArr.push(this.activeArr[i])
                : "";
            }
            this.activeArr = newArr;
          }
        });
        this.activeAreaArr = this.unique(this.activeAreaArr); //景点属性去重
        this.scenicSpotArr.forEach((item11) => {
          this.activeAreaArr.forEach((item12) => {
            item12 == item11.areaType ? this.showList.push(item11) : "";
            item12 == item11.areaType ? console.log(item11) : "";
          });
        });
      } else {
        this.activeArr = [1];
        this.showList = this.scenicSpotArr;
      }
    },
    unique(arr) {
      var result = [];
      for (var i = 0; i < arr.length; i++) {
        for (var j = i + 1; j < arr.length; j++) {
          if (arr[i] === arr[j]) {
            j = ++i;
          }
        }
        result.push(arr[i]);
      }
      return result;
    },
    checkArray(arr, target) {
      return arr.indexOf(target);
    },
    handleClose(done) {
      done();
    },
    splitBatName(str) {
      return str;
    },
  },
};
</script>

<style>
@import "~@/assets/css/zh.scss";
.loadImg {
  width: 100%;
  height: auto;
}
.train-footer-img1,
.train-footer-img2 {
  bottom: -279px;
}
</style>