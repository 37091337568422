<template>
  <div @load="loading()">
    <div class="touch" @touchstart='touchstart' @touchmove='touchmove'>
      <topMb></topMb>
      <!-- 轮播 -->
      <el-carousel :height="banH">
        <el-carousel-item v-for="item in bannerMb" :key="item" class="carousel-item">
          <img ref="imgH" class="carousel-img" :src="item" @load="setbanH()" />
        </el-carousel-item>
      </el-carousel>
      <!-- 切换 -->
      <div class="main-travel ">
        <div class="train-box">
          <div class="app-container">
            <div class="col-md-12 travel-item-box">
              <el-image src="https://qbcdn.jtrchina.com/tripelletexploreallpass/tracks-1.png" lazy>
                <div slot="placeholder" class="image-slot">
                  <img :src="loadImg" class="loadImg" alt="" />
                </div>
              </el-image>
              <div class="travel-item-md6" v-for="(item,i) in ticketArr" :key="i" @click="change(item)">
                <div :class="[active===item.id ? 'travel-item1' : 'travel-item']">
                  <el-image class="travel-item-img" :src="item.img" lazy></el-image>
                  <p class="travel-right">VALID FOR
                    <span class="travel-right-day">
                      <span :style="{'color':item.fontcolor}">{{item.validityDay}}</span>&nbsp;Days</span>
                  </p>
                  <p class="travel-detail" v-for="(item ,index) in splitBatName(item.describe)" :key="index">{{item}}</p>
                  <p class="travel-price" :style="{'color':item.fontcolor}">￥{{item.price}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 套餐内容 -->
      <div class="main-package">
        <div class="app-container">
          <div class="col-md-12  package-content-box">
            <div class="package-content flex-arround">
              <el-image class="package-star" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/star-right.png" lazy>
                <div slot="placeholder" class="image-slot">
                  <img :src="loadImg" class="loadImg" alt="" />
                </div>
              </el-image>
              <p class="package-title">Included in the All Pass</p>
              <el-image class="package-star" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/star-left.png" lazy>
                <div slot="placeholder" class="image-slot">
                  <img :src="loadImg" class="loadImg" alt="" />
                </div>
              </el-image>
            </div>
          </div>
          <div class="col-md-12 flex-justify-c">
            <div class="package-riding-content2">
              <div class="riding-box">
                <div class="riding-right">
                  <p class="riding-right-title">JR Ticket</p>
                  <p class="right-positionEn">Can be used for reserved seating</p>
                  <p class="riding-right-titleEn">Kansai-Airport Express HARUKA One-way ticket</p>
                  <p class="riding-right-titleEn2">(from Kansai-Airport to Kyoto Station)</p>
                  <div class="riding-line"></div>
                  <p class="riding-right-title3">The Kansai-Airport Express HARUKA<br>One-way ticket can be exchanged for<br>
                    an unlimited JR-WEST RAIL PASS.</p>
                  <el-image class="riding-right-img" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/riding-2.png" lazy>
                    <div slot="placeholder" class="image-slot">
                      <img :src="loadImg" class="loadImg" alt="" />
                    </div>
                  </el-image>
                  <el-image class="riding-copyright" :src="copyright" lazy>
                    <div slot="placeholder" class="image-slot">
                      <img :src="loadImg" style="width: 309px;height:10px;margin-left: -12px;" alt="" />
                    </div>
                  </el-image>
                  <div class="col-md-12 five_days_box">
                    <div class="five_days_title" :class="[active===2 ? 'riding-bottom-1-1' : '']">
                      <div>
                        <p class="riding-bottom-t" v-for="(item ,index) in splitBatName(planName)" :key="index">{{item}}</p>
                      </div>
                      <p class="riding-bottom-p">￥{{planPrice}}</p>
                    </div>
                  </div>
                  <div class="col-md-12 five_days_box" v-if="planName2 && planPrice2" :class="[active===2 ? 'riding-bottom-2-1' : '']">
                    <div class="five_days_title">
                      <div>
                        <p class="riding-bottom-t" v-for="(item ,index) in splitBatName(planName2)" :key="index">{{item}}</p>
                      </div>
                      <p class="riding-bottom-p">￥{{planPrice2}}</p>
                    </div>
                  </div>
                  <div class="col-md-12 five_days_box" v-if="planName3 && planPrice3">
                    <div class="five_days_title">
                      <p class="riding-bottom-t">{{planName3}}</p>
                      <p class="riding-bottom-p">￥{{planPrice3}}</p>
                    </div>
                  </div>
                  <div class="col-md-12 five_days_box">
                    <div class="five_days_attention">
                      <p>※One-use only</p>
                      <p>※Can also be used to get off at Tennoji or<br> &nbsp;&nbsp;&nbsp;&nbsp;Shin-Osaka stations</p>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <!-- 日本环球影城 -->
          <div class="package-universal-content2 ">
            <div class="universal-left">
              <p class="universal-left-1">Universal Studios <br>Japan<br> 1 Day Studio Pass</p>
              <el-image class="lazy universal-img" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/universal-zh1.jpg" lazy>
                <div slot="placeholder" class="image-slot">
                  <img :src="loadImg" class="loadImg" alt="" />
                </div>
              </el-image>
              <p class="universal-left-3">
                <span>Experience the world's first* Mario-themed area and Mario Kart: Koopa's Challenge™ attraction, both of
                  which received Thea Awards, considered an enormous honor around the globe in the themed entertainment
                  industry. And don't miss The Wizarding World of Harry Potter™, Minions, and other magnificent worlds
                  enjoyed by kids and adults alike!</span>
                <span><br>Exhilarate your senses with a day of play in a park full of endless excitement and thrills!</span>
              </p>
              <div class="universal-left-4">
                <p>*Based on research by USJ LLC into themed establishments based on Nintendo characters and worlds.</p>
              </div>
            </div>
          </div>
          <!-- 观光设施共通劵 -->
          <div class="facilities-content2 ">
            <div class="facilities-box flex-arround">
              <el-image class="lazy facilities-star" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/star-right.png" lazy>
                <div slot="placeholder" class="image-slot">
                  <img :src="loadImg" class="loadImg" alt="" />
                </div>
              </el-image>
              <p class="facilities-title">Ticket Valid<br><span>{{md3[0].num}}facilities</span></p>
              <el-image class="lazy facilities-star" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/star-left.png" lazy>
                <div slot="placeholder" class="image-slot">
                  <img :src="loadImg" class="loadImg" alt="" />
                </div>
              </el-image>
            </div>
            <div class="facilities-attention2 ">
              <p>1. For the latest information regarding<br>each facilities' business hours and notice,<br>please check the
                official website.</p>
              <p>2. Intervals of 2 hours is required<br>between each facility used.</p>
              <p>3. Repeated use on the same facility is not allowed.</p>
            </div>
            <div class="area-box">
              <div class="area-Box">
                <div class="col-md-12">
                  <div class="all-area" :style="{backgroundColor:(activeArr.includes(1)?'#ff7b00':'#fff'),color:(activeArr.includes(1)?'#fff':'#ff7b00')}" @click="activeArea({id:1,areaType:0})">ALL AREA
                  </div>
                </div>
                <div class="col-md-12" style="display: flex;justify-content: space-between;">
                  <div class="area" :style="{backgroundColor:(activeArr.includes(2)?'#ff7b00':'#fff'),color:(activeArr.includes(2)?'#fff':'#ff7b00')}" @click="activeArea({id:2,areaType:2})">OSAKA</div>
                  <div class="area" :style="{backgroundColor:(activeArr.includes(3)?'#ff7b00':'#fff'),color:(activeArr.includes(3)?'#fff':'#ff7b00')}" @click="activeArea({id:3,areaType:1})">KYOTO</div>
                </div>
                <div class="col-md-12">
                  <div class="area2" :style="{backgroundColor:(activeArr.includes(5)?'#ff7b00':'#fff'),color:(activeArr.includes(5)?'#fff':'#ff7b00')}" @click="activeArea({id:5,areaType:3})">SHIGA．HYOGO．WAKAYAMA</div>
                  <div class="col-md-12" style="display: flex;justify-content: space-between;">
                    <div class="area-md3" v-show="this.active===1" :style="{backgroundColor:(activeArr.includes(4)?'#ff7b00':'#fff'),color:(activeArr.includes(4)?'#fff':'#ff7b00')}" @click="activeArea({id:4,areaType:5})">ISHIKAWA</div>
                    <div class="area3" v-show="this.active===1" :style="{backgroundColor:(activeArr.includes(6)?'#ff7b00':'#fff'),color:(activeArr.includes(6)?'#fff':'#ff7b00')}" @click="activeArea({id:6,areaType:4})">SANIN．SANYO</div>
                  </div>
                </div>
                <div class="col-md-12 search-bg" style="display: flex; justify-content: center;">
                  <div class="area-search" @click="drawer = true">SEARCH</div>
                </div>
              </div>
              <div class="area-small-attention">
                <p>Notes</p>
                <p>*The validity period of ALL PASS is indicated in the title, regardless of the validity of the area pass.
                </p>
                <p>*Please make sure you redeem your Haruka one-way ticket and/or area pass before your All Pass expires.
                </p>
                <p>*If you do not redeem them before the expiry date they will be invalid. </p>
              </div>
              <el-image class="area-small-bg" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/small_bg.png" lazy>
                <div slot="placeholder" class="image-slot">
                  <img :src="loadImg" class="loadImg" alt="" />
                </div>
              </el-image>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 抽屉 -->
    <div class="touch" @touchstart='touchstart' @touchmove='touchmove1' style="touch-action: pan-y!important;">
      <el-drawer title="" :visible.sync="drawer" :with-header="true" :destroy-on-close="true">
        <!-- 观光设施 抽屉-->
        <div class="main-facilities">
          <div class="facilities-box2">
            <el-image class="facilities-star" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/star-left.png" lazy>
              <div slot="placeholder" class="image-slot">
                <img :src="loadImg" class="loadImg" alt="" />
              </div>
            </el-image>
            <p class="facilities-title">Ticket Valid</p>
            <el-image class="facilities-star" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/star-right.png" lazy>
              <div slot="placeholder" class="image-slot">
                <img :src="loadImg" class="loadImg" alt="" />
              </div>
            </el-image>
          </div>
          <div class="col-md-12">
            <div class="area-title">
              <div class="area-title1">
                <div class="area-title-item">
                  <span :style="{backgroundColor:((activeArr.includes(2) || activeArr.includes(1))?'#ff7b00':'#fff'),color:((activeArr.includes(2) || activeArr.includes(1))?'#fff':'#ff7b00'),borderRadius:((activeArr.includes(2) || activeArr.includes(1))?'10px':'0')}" @click="activeArea({id:2,areaType:2})">OSAKA</span>
                </div>
                <div class="area-title-item" :style="{borderRight:(this.active===1?'1px solid #ff7b00':'0')}">
                  <span :style="{backgroundColor:((activeArr.includes(3) || activeArr.includes(1))?'#ff7b00':'#fff'),color:((activeArr.includes(3) || activeArr.includes(1))?'#fff':'#ff7b00'),borderRadius:((activeArr.includes(3) || activeArr.includes(1))?'10px':'0')}" @click="activeArea({id:3,areaType:1})">KYOTO</span>
                </div>
              </div>
              <div class="area-title1">
                <div class="area-title-item" v-show="this.active===1">
                  <span :style="{backgroundColor:((activeArr.includes(4) || activeArr.includes(1))?'#ff7b00':'#fff'),color:((activeArr.includes(4) || activeArr.includes(1))?'#fff':'#ff7b00'),borderRadius:((activeArr.includes(4) || activeArr.includes(1))?'10px':'0'),width:(activeArr.includes(4)?'52px':'84px')}" @click="activeArea({id:4,areaType:5})">ISHIKAWA</span>
                </div>
                <div class="area-title-item1">
                  <span :style="{backgroundColor:((activeArr.includes(6) || activeArr.includes(1))?'#ff7b00':'#fff'),color:((activeArr.includes(6) || activeArr.includes(1))?'#fff':'#ff7b00'),borderRadius:((activeArr.includes(6) || activeArr.includes(1))?'10px':'0')}" @click="activeArea({id:6,areaType:4})">SANIN．SANYO</span>
                </div>
              </div>
              <div class="area-title1">
                <div class="area-title-item2" v-show="this.active===1">
                  <span :style="{backgroundColor:((activeArr.includes(5) || activeArr.includes(1))?'#ff7b00':'#fff'),color:((activeArr.includes(5) || activeArr.includes(1))?'#fff':'#ff7b00'),borderRadius:((activeArr.includes(5) || activeArr.includes(1))?'10px':'0')}" @click="activeArea({id:5,areaType:3})">SHIGA．HYOGO．WAKAYAMA</span>
                </div>
              </div>
            </div>
          </div>
          <div class="facilities-item-box">
            <div class="col-md-12" v-for="(item,i) in showList" :key="i" @click="openSmall(item)">
              <div class="facilities-item" v-if="active=== 1">
                <el-image class="facilities-item-img" :src="item.img" lazy>
                  <div slot="placeholder" class="image-slot">
                    <img :src="loadImg" class="loadImg" alt="" />
                  </div>
                </el-image>
                <div class="facilities-item-title">
                  <p style="width: 293px;margin-bottom: 0;;" v-for="(item ,index) in splitBatName(item.ticketName3)" :key="index">
                    {{item}}</p>
                </div>
              </div>
              <div class="facilities-item" v-else v-show="active === 2 && item.activeId === 1">
                <el-image class="facilities-item-img" :src="item.img" lazy>
                  <div slot="placeholder" class="image-slot">
                    <img :src="loadImg" class="loadImg" alt="" />
                  </div>
                </el-image>
                <div class="facilities-item-title">
                  <p style="width: 293px;margin-bottom: 0;;" v-for="(item ,index) in splitBatName(item.ticketName3)" :key="index">
                    {{item}}</p>
                </div>
              </div>
            </div>
          </div>
          <!-- 底部底图 -->
          <div class="language-bg-box">
            <div class="demo-image__lazy">
              <el-image class="language-bg" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/small_bg.png" style="width: 100%;"></el-image>
            </div>
          </div>
        </div>
        <!-- 观光设施详情页 -->
        <el-drawer title="" :append-to-body="true" :visible.sync="innerDrawer">
          <div class="touch" @touchstart='touchstart' @touchmove='touchmove2'>
            <el-carousel height="210px" :interval="2000" ref="nop" arrow="never">
              <el-carousel-item v-for="(item,index) in ticketDetials.tickeImg" :key="index">
                <img :src="item" style="height:100%;width:100%;" lazy />
              </el-carousel-item>
              <!-- </div> -->
            </el-carousel>
            <div class="main-facility">
              <div class="app-container">
                <p class="travel-copyright" v-if="ticketDetials.copyright">{{ticketDetials.copyright}}</p>
                <div class="col-md-12">
                  <div class="facility-title">{{ticketDetials.areaNAme}}</div>
                </div>
                <div class="col-md-12 facility-title2-box">
                  <div class="facility-title2">
                    <p v-for="(item ,index) in splitBatName(ticketDetials.ticketName4)" :key="index">{{item}}</p>
                  </div>
                </div>
                <div class="col-md-12  facility-travel-box">
                  <el-image class="facility-img" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/facility_area.png" lazy>
                    <div slot="placeholder" class="image-slot">
                      <img :src="loadImg" class="loadImg" alt="" />
                    </div>
                  </el-image>
                  <p class="facility-travel-p">
                    <a :href="ticketDetials.Http" target="_ blank" rel="noopener noreferrer">{{ticketDetials.Http}}</a>
                    <a :href="ticketDetials.Http2" target="_ blank" rel="noopener noreferrer">{{ticketDetials.Http2}}</a>
                  </p>
                  <!-- <p class="facility-travel-p" style="margin-left: 0;color: #000;">{{ticketDetials.Http2}}</p> -->
                </div>
                <div class="col-md-12  facility-travel-box">
                  <el-image class="facility-img" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/travel-facilities.png" lazy>
                    <div slot="placeholder" class="image-slot">
                      <img :src="loadImg" class="loadImg" alt="" />
                    </div>
                  </el-image>
                  <div>
                    <p class="facility-travel-p" v-for="(item ,index) in splitBatName(ticketDetials.Faci)" :key="index">{{item}}</p>
                  </div>
                </div>
                <div class="col-md-12  facility-travel-box">
                  <el-image class="facility-travel-img" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/travel-cart.png" lazy>
                    <div slot="placeholder" class="image-slot">
                      <img :src="loadImg" class="loadImg" alt="" />
                    </div>
                  </el-image>
                  <div>
                    <p class="facility-travel-p" v-for="(item ,index) in splitBatName(ticketDetials.Doer)" :key="index">{{item}}</p>
                  </div>
                </div>
                <div class="col-md-12  facility-travel-box">
                  <el-image class="facility-travel-img" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/travel-name.png" lazy>
                    <div slot="placeholder" class="image-slot">
                      <img :src="loadImg" class="loadImg" alt="" />
                    </div>
                  </el-image>
                  <div>
                    <p class="facility-travel-p" v-for="(item ,index) in splitBatName(ticketDetials.time)" :key="index">{{item}}</p>
                  </div>
                </div>
                <div class="col-md-12 facility-travel-box">
                  <el-image class="facility-travel-img" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/travel-calendar.png" lazy>
                    <div slot="placeholder" class="image-slot">
                      <img :src="loadImg" class="loadImg" alt="" />
                    </div>
                  </el-image>
                  <div>
                    <p class="facility-travel-p" v-for="(item ,index) in splitBatName(ticketDetials.Calendar)" :key="index">{{item}}</p>
                  </div>
                </div>
                <div class="col-md-12 facility-travel-box">
                  <el-image class="facility-travel-img" src="https://qbcdn.jtrchina.com/tripelletexploreallpass/travel-price.png" lazy>
                    <div slot="placeholder" class="image-slot">
                      <img :src="loadImg" class="loadImg" alt="" />
                    </div>
                  </el-image>
                  <p class="facility-travel-p">￥{{ticketDetials.price}}</p>
                </div>
                <div class="col-md-12 facility-travel-box" v-if="ticketDetials.content2">
                  <div>
                    <p class="facility-travel-p" v-for="(item ,index) in splitBatName(ticketDetials.content2)" :key="index">{{item}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-drawer>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import topMb from "@/components/top-all/top-Mball3.vue";
export default {
  components: { topMb },
  data() {
    return {
      banH: "",
      active: 1,
      animateTime: 3000, // 要和 css 的过渡一致
      ticketDescribe: "Ticket Valid  ( 29 facilities)",
      planName: ["Kansai Wide-Area Pass", "5 Days Plan"],
      planPrice: "27,700",
      planName2: ["Kansai-Hiroshima Area Pass", "5 Days Plan"],
      planPrice2: "32,000",
      planName3: "JR-WEST All Area Pass",
      planPrice3: "39,000",
      ticketDetials: {},
      showDialog: false,
      innerDrawer: false,
      drawer: false,
      startX: "",
      startY: "",
      moveY: "",
      moveX: "",
      startTime: "",
      activeArr: [1],
      activeAreaArr: [],
      showList: [],
      loadImg: require("@/assets/images/hfkansai/loading.png"),
      copyright: require("@/assets/images/allpass/copyright.png"),
      bannerMb: [
        "https://qbcdn.jtrchina.com/tripelletexploreallpass/banner/mb_banner.png",
        require("@/assets/images/allpass/MB_ch.png"),
      ],
      ticketArr: [
        {
          id: 1,
          type: 1,
          fontcolor: "#F98304",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/travel-12.png",
          ticketName: "EXPLORE WEST JAPAN ALL PASS",
          validityDay: 4,
          price: "21,000~",
          describe: [
            "JR Ticket + ",
            "Ticket valid at 29 tourism facilities",
            "+ Universal Studios Japan",
            "1 Day Studio Pass",
          ],
        },
        {
          id: 2,
          type: 2,
          fontcolor: "#F4B22D",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/travel-22.png",
          ticketName: "EXPLORE KANSAI AREA ALL PASS",
          validityDay: 3,
          price: "16,000~",
          describe: [
            "JR Ticket + ",
            "Ticket valid at 16 tourism facilities",
            "+ Universal Studios Japan",
            "1 Day Studio Pass",
          ],
        },
      ],
      scenicSpotArr: [
        // 京都
        {
          id: 1,
          areaType: 1,
          activeId: 1,
          areaNAme: "KYOTO",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/jingdu_A.jpg",
          ticketName: ["Kyoto Railway Museum Admission Ticket"],
          ticketName3: ["Kyoto Railway Museum Admission Ticket"],
          ticketName4: ["Kyoto Railway Museum Admission Ticket"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/jingdu_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/jingdu_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/jingdu_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/jingdu_D.jpg",
          ],
          Doer: ["2 min walk from Umekoji-Kyotonishi Station"],
          time: ["10:00 AM ~ 17:30 PM", "(Last entry 16:30 PM)"],
          Http: "http://www.kyotorailwaymuseum.jp/en/",
          Faci: [
            "Kankijicho, Shimogyo-ku, Kyoto",
            "(Kyoto Railway Museum Ticket Center)",
          ],
          Faci2: [
            "Kankijicho, Shimogyo-ku, Kyoto",
            "(Kyoto Railway Museum Ticket Center)",
          ],
          Calendar: [
            "Wed., New Years",
            "※It may be temporarily closed,",
            "please check the latest",
            "information on the official website in advance.",
          ],
          Calendar2: [
            "Wed., New Years",
            "※It may be temporarily closed,",
            "please check the latest",
            "information on the official website in advance.",
          ],
          price: "1,200",
        },
        {
          id: 2,
          areaType: 1,
          activeId: 1,
          areaNAme: "KYOTO",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/dongyin_A.jpg",
          ticketName: ["Toei Kyoto Studio Park Admission Ticket "],
          ticketName3: ["Toei Kyoto Studio Park Admission Ticket "],
          ticketName4: ["Toei Kyoto Studio Park Admission Ticket "],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/dongyin_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/dongyin_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/dongyin_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/dongyin_D.jpg",
          ],
          Doer: ["13 min walk from JR Hanazono Station"],
          time: [
            "10:00 AM ～ 17:00 PM",
            "※It may be temporarily closed, please check the latest",
            "information on the official website in advance.",
          ],
          Http: "http://www.toei-eigamura.com/global/en/",
          Faci: [
            "10 Uzumasa Higashihachiokacho,",
            "Ukyo-ku Kyoto-shi (Ticket Center)",
          ],
          Faci2: [
            "10 Uzumasa Higashihachiokacho,",
            "Ukyo-ku Kyoto-shi (Ticket Center)",
          ],
          Calendar: ["※Please refer to the official website"],
          Calendar2: ["※Please refer to the official website"],
          price: "2,400",
        },
        {
          id: 3,
          areaType: 1,
          activeId: 1,
          areaNAme: "KYOTO",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/lanshan_A.jpg",
          ticketName: ["Arashiyama Gourmet Coupon+Randen", "1 Day Free Ticket"],
          ticketName3: [
            "Arashiyama Gourmet Coupon+Randen",
            "1 Day Free Ticket",
          ],
          ticketName4: [
            "Arashiyama Gourmet Coupon+Randen",
            "1 Day Free Ticket",
          ],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/lanshan_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/lanshan_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/lanshan_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/lanshan_D.jpg",
          ],
          Doer: ["8 min walk from JR Saga-Arashiyama Station"],
          time: [
            'Randen "Arashiyama station information"',
            "9:00 AM ~ 18:00 PM",
          ],
          Http: "https://www.tripellet.com/gurumeguri_map2022.pdf",
          Http2: "https://www.tripellet.com/gurumeguri2022.pdf",
          Faci: [
            "20-2 Sagatenryuji Tsukurimichicho, Ukyo Ward",
            "Kyoto (Arashiyama Station Information Center)",
          ],
          Faci2: [
            "20-2 Sagatenryuji",
            "Tsukurimichicho, Ukyo Ward",
            "Kyoto (Randen Arashiyama Eki",
            "Information Center)",
          ],
          Calendar: [
            "※Please refer to the coupon for",
            "the business hours of each branch.",
          ],
          Calendar2: [
            "※Please refer to the coupon for",
            "the business hours of each branch.",
          ],
          price: "1,000",
          isHttp: 1,
        },
        {
          id: 4,
          activeId: 1,
          areaType: 1,
          areaNAme: "KYOTO",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/Sky Hop Bus_A.jpg",
          ticketName: ["Kyoto Sky Hop Bus", "1 Day Ticket"],
          ticketName3: ["Kyoto Sky Hop Bus", "1 Day Ticket"],
          ticketName4: ["Kyoto Sky Hop Bus", "1 Day Ticket"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/Sky Hop Bus_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/Sky Hop Bus_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/Sky Hop Bus_C.jpg",
          ],
          Doer: ["1 min walk from JR Kyoto Station (Karasuma exit)"],
          time: ["Read more on the web (temporarily no service sometimes)"],
          Http: "https://skyhopbus.com/kyoto/",
          Faci: [
            "Higashishiokojicho Karasumadori Shiokoji Sagaru,",
            "Shimogyo-ku Kyoto Sightseeing Bus Terminal (Karasumaguchi)",
          ],
          Faci2: [
            "Higashishiokojicho Karasumadori Shiokoji Sagaru,",
            "Shimogyo-ku Kyoto Sightseeing Bus Terminal (Karasumaguchi)",
          ],
          Calendar: ["※Please refer to the official website"],
          Calendar2: ["※Please refer to the official website"],
          price: "2,500",
        },
        {
          id: 5,
          activeId: 1,
          areaType: 1,
          areaNAme: "KYOTO",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/hefu_A.jpg",
          ticketName: ["Kyoto Kimono Rental ", "Yumeyakata Gojo Shop"],
          ticketName3: ["Kyoto Kimono Rental ", "Yumeyakata Gojo Shop"],
          ticketName4: ["Kyoto Kimono Rental ", "Yumeyakata Gojo Shop"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/hefu_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/hefu_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/hefu_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/hefu_D.jpg",
          ],
          Doer: [
            "From JR Kyoto Station take the Karasuma subway line to Gojo Station, then 3 min walk",
          ],
          time: ["10:00 AM ～ 17:30 PM (Last entry 16:00 PM)"],
          Http: "https://www.en-kyoto.yumeyakata.com/",
          Faci: ["353 Shiogamacho, Shimogyo Ward, Kyoto"],
          Faci2: ["353 Shiogamacho, Shimogyo Ward, Kyoto"],
          Calendar: ["Year-end and new year (12/28 ～ 1/3)"],
          Calendar2: ["Year-end and new year (12/28 ～ 1/3)"],
          price: "3,850",
        },
        {
          id: 6,
          areaType: 1,
          areaNAme: "KYOTO",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/tianqiao_A.jpg",
          ticketName: ["Amanohashidate Ine Tourist", "2 Day Ticket"],
          ticketName2: ["(Bus・Boat・Cable Car)"],
          ticketName3: [
            "Amanohashidate Ine Tourist 2 Day Ticket",
            "(Bus・Boat・Cable Car)",
          ],
          ticketName4: [
            "Amanohashidate Ine Tourist 2 Day Ticket",
            "(Bus・Boat・Cable Car)",
          ],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/tianqiao_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/tianqiao_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/tianqiao_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/jingdu/tianqiao_D.jpg",
          ],
          Doer: ["Inside Amanohashidate Station on the Kyoto Tango Railway"],
          time: [
            "9:00 AM ~ 16:30 PM",
            "(Amanohashidate Terminal Center",
            "Tourist Information Center)",
          ],
          Http: "https://www.tankai.jp/tourist_tickets/",
          Faci: [
            "Miyazu City, Kyoto Prefecture,",
            "314-2 (Amanohashidate",
            "Tourism Association Center)",
          ],
          Faci2: [
            "Miyazu City, Kyoto Prefecture,314-2 (Amanohashidate Tourism Association Center)",
          ],
          Calendar: ["Open all year"],
          Calendar2: ["Open all year"],
          price: "2,600",
        },
        // 大阪
        {
          id: 7,
          activeId: 1,
          areaType: 2,
          areaNAme: "OSAKA",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/meitian_A.jpg",
          ticketName: [
            "Umeda Sky Building Kuchu Teien Observatory",
            "Admission Ticket",
          ],
          ticketName3: [
            "Umeda Sky Building Kuchu Teien Observatory Admission Ticket",
          ],
          ticketName4: [
            "Umeda Sky Building Kuchu Teien Observatory Admission Ticket",
          ],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/meitian_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/meitian_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/meitian_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/meitian_D.jpg",
          ],
          Doer: ["7 min walk from JR Osaka Station"],
          time: ["9:30 AM ~ 10:30 PM ", "(Last entry 10:00 PM)"],
          Http: "https://www.skybldg.co.jp/en/",
          Faci: [
            "Umeda Sky Building, 1-1-88 Oyodonaka,",
            "Kita Ward, Osaka (39F Ticket Counter)",
          ],
          Faci2: [
            "Umeda Sky Building, 1-1-88 Oyodonaka, Kita Ward, Osaka",
            "(39F Ticket Counter)",
          ],
          Calendar: ["Open all year"],
          Calendar2: ["Open all year"],
          price: "1,500",
        },
        {
          id: 8,
          activeId: 1,
          areaType: 2,
          areaNAme: "OSAKA",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/HARUKAS_A.jpg",
          ticketName: ["ABENO HARUKAS", "Admission Ticket"],
          ticketName3: ["ABENO HARUKAS Admission Ticket"],
          ticketName4: ["ABENO HARUKAS Admission Ticket"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/HARUKAS_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/HARUKAS_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/HARUKAS_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/HARUKAS_D.jpg",
          ],
          Doer: ["Near JR Tennoji Station"],
          time: ["9:00 AM ~ 10:00 PM ", "(Last entry 7:30 PM)"],
          Http: "https://www.abenoharukas-300.jp/en/",
          Faci: ["16F, 1-1-43Abenosuji, Abeno-ku, Osaka, Japan"],
          Faci2: ["16F, 1-1-43Abenosuji, Abeno-ku, Osaka, Japan"],
          Calendar: ["Open all year"],
          Calendar2: ["Open all year"],
          price: "1,500",
        },
        {
          id: 9,
          activeId: 1,
          areaType: 2,
          areaNAme: "OSAKA",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/gelan_A1.jpg",
          ticketName: ["Hotel Granvia Osaka", "Tea Lounge", "Dessert Set"],
          ticketName3: ["Hotel Granvia Osaka", "Tea Lounge Dessert Set"],
          ticketName4: ["Hotel Granvia Osaka", "Tea Lounge Dessert Set"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/gelan_A1.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/gelan_B1.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/gelan_C1.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/gelan_D1.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/gelan_E1.jpg",
          ],
          Doer: ["Connected to JR Osaka Station  Central Count"],
          time: [
            "Dessert Serving Time ：11:00 AM ~ 16:00 PM",
            "Weekday ： 10:00 AM ~ 20:00 PM（Lo19:30 PM）",
            "Saturday：9:30 AM ~ 20:00 PM（Lo19:30 PM）",
            "Sunday / Holiday：9:30 AM ~ 18:00 PM（Lo17:30 PM）",
          ],
          Http: "https://www.hotelgranviaosaka.jp/en/dining/lobby-lounge/",
          Faci: ["3-1-1 Umeda, Kita-ku, Osaka", "(1F Tea Lounge)"],
          Faci2: ["3-1-1 Umeda, Kita-ku, Osaka", "(1F Tea Lounge)"],
          Calendar: ["Open all year"],
          Calendar2: ["Open all year"],
          price: "2,300",
          content: "",
          content2: [
            "※ The images are for references only.",
            "※ Meal contents vary seasonally.",
          ],
        },
        {
          id: 10,
          activeId: 1,
          areaType: 2,
          areaNAme: "OSAKA",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/bashi_A.jpg",
          ticketName: ["Osaka Aqua Bus"],
          ticketName3: ["Osaka Aqua Bus"],
          ticketName4: ["Osaka Aqua Bus"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/bashi_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/bashi_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/bashi_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/bashi_D.jpg",
          ],
          Doer: [
            "3-minute walk from JR Osaka",
            "Loop Line Osakajo-koen Station",
          ],
          time: [
            "11:00 AM / 11:45 AM / 12:30 AM",
            "13:15 PM / 14:00 PM / 14:45 PM /",
            "15:30 PM / 16:15 PM",
          ],
          price: "1,600",
          Http: " http://suijo-bus.osaka",
          Faci: [
            "Osakajo, Chuo-ku, Osaka City",
            "(Osaka Aqua Bus stop Osaka Castle Port",
            "boarding ticket counter)",
          ],
          Faci2: [
            "Osakajo, Chuo-ku, Osaka City",
            "(Osaka Aqua Bus stop Osaka Castle Port boarding ticket counter)",
          ],
          Calendar: [
            "Non-Operating Hours: 2 days of weekdays",
            "/ Afternoon on July 25th / Mid-January",
            "/ Early February",
          ],
          Calendar2: [
            "Non-Operating Hours:",
            "2 days of weekdays",
            "/ Afternoon on July 25th / Mid-January",
            "/Early February",
          ],
          content: "",
          content2: [
            "※Special prices are available in spring",
            "(Late March to early April).",
          ],
        },
        {
          id: 11,
          activeId: 1,
          areaType: 2,
          areaNAme: "OSAKA",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/daban_A.jpg",
          ticketName: ["Osaka Museum of", "Housing and Living"],
          ticketName3: ["Osaka Museum of Housing and Living"],
          ticketName4: ["Osaka Museum of Housing and Living"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/daban_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/daban_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/daban/daban_C.jpg",
          ],
          Doer: ["7 min walk from JR Tenma Station"],
          time: ["10:00 AM ~ 17:00 PM", "(Last entry 4:30 PM)"],
          Http: "http://konjyakukan.com/",
          Faci: [
            "6-Chome 4-20, Tenjinbashi, Kita-ku,",
            "Osaka-shi 8F of residence",
            "information center building",
            "(Ticket Center)",
          ],
          Faci2: [
            "6-Chome 4-20, Tenjinbashi, Kita-ku,",
            "Osaka-shi 8F of residence",
            "information center building",
            "(Ticket Center)",
          ],
          Calendar: ["Tuesday．Year-end and new year (12/28 ～ 1/4)"],
          Calendar2: ["Tuesday．Year-end and new year", "(12/28 ～ 1/4)"],
          price: "400",
        },
        // 滋賀・兵庫・和歌山
        {
          id: 12,
          activeId: 1,
          areaType: 3,
          areaNAme: "HYOGO",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/shenhu_A1.jpg",
          ticketName: ["Kobe Nunobiki Herb Gardens", "& Ropeway Ticket"],
          ticketName3: ["Kobe Nunobiki Herb Gardens & Ropeway Ticket"],
          ticketName4: ["Kobe Nunobiki Herb Gardens & Ropeway Ticket"],
          Doer: ["5 min walk from JR Shin-Kobe Station"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/shenhu_A1.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/shenhu_B1.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/shenhu_C1.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/shenhu_D1.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/shenhu_E1.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/shenhu_F1.jpg",
          ],
          time: ["(Sanroku Station 4F Information)", "9:30 AM ~ 16:45 PM"],
          Http: "https://www.kobeherb.com/en/",
          Faci: [
            "1-4-3 Kitanocho, Chuo-ku, Kobe, Hyogo",
            "(4F Information Center of Sanroku Station)",
          ],
          Faci2: [
            "1-4-3 Kitanocho, Chuo-ku, Kobe, Hyogo (4F Information Center of ",
            "Sanroku Station)",
          ],
          Calendar: ["Please refer to the official website"],
          Calendar2: ["Please refer to the official website"],
          price: "1,800",
        },
        {
          id: 13,
          activeId: 1,
          areaType: 3,
          areaNAme: "HYOGO",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/mingshi_A.jpg",
          ticketName: ["「WAZANOIPPINN」Akashi", "Sushi Coupon of JYP2000"],
          ticketName3: ["「WAZANOIPPINN」Akashi Sushi Coupon of JYP2000"],
          ticketName4: ["「WAZANOIPPINN」Akashi Sushi Coupon of JYP2000"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/mingshi_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/mingshi_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/mingshi_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/mingshi_D.jpg",
          ],
          Doer: ["near JR Akashi Station"],
          time: [
            "(weekdays) 9:00 AM ~ 19:00 PM",
            "(weekends, holidays) 9:00 AM ~ 18:00 PM",
          ],
          Http: "https://www.yokoso-akashi.jp/sushi_coupon",
          Faci: [
            "1-1-23 Oakashicho Akashi, Hyogo (Akashi Tourist Information Center at Piole West Hall)",
          ],
          Faci2: [
            "1-1-23 Oakashicho Akashi, Hyogo (Akashi Tourist Information Center at Piole West Hall)",
          ],
          Calendar: ["Year-end and New year (12/31 ～ 1/1)"],
          Calendar2: ["Year-end and New year", "(12/31 ～ 1/1)"],
          price: "2,000",
        },
        {
          id: 14,
          activeId: 1,
          areaType: 3,
          areaNAme: "HYOGO",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/kexueguan_A.jpg",
          ticketName: [
            "Hyogo Prefecture Akashi Municipal Planetarium Admission Ticket",
          ],
          ticketName3: [
            "Hyogo Prefecture Akashi Municipal Planetarium Admission Ticket",
          ],
          ticketName4: [
            "Hyogo Prefecture Akashi Municipal Planetarium Admission Ticket",
          ],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/kexueguan_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/kexueguan_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/kexueguan_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/kexueguan_D.jpg",
          ],
          Doer: [
            "15 min walk from JR Akashi Station,",
            "3 min walk from Hitomarumae",
            "Station on Sanyo Electric Railway",
          ],
          time: [
            "Akashi Information Center",
            "(Weekdays) 9:00 AM ~ 19:00 PM",
            "(Weekends and holidays)",
            "9:00 AM ~ 18:00 PM",
            "Akashi Municipal Planetarium :",
            "9:30 AM ~ 17:00 PM",
            "(Doors close at 16:30 PM)",
          ],
          Http: "https://www.am12.jp/",
          Faci: [
            "Exchange location： Akashi City, Hyogo",
            "Prefecture1-1-23",
            "Oakashicho Piole Akashi West Building",
            "Facility address：2-6 Hitomarucho, Akashi City,",
            "Hyogo Prefecture",
          ],
          Faci2: [
            "Exchange location： Akashi City, Hyogo",
            "Prefecture1-1-23",
            "Oakashicho Piole Akashi West Building",
            "Facility address：2-6 Hitomarucho, Akashi City,",
            "Hyogo Prefecture",
          ],
          Calendar: [
            "year-end and new year holidays",
            "※It may be temporarily closed,",
            "please check the latest",
            "information on the official website",
            "in advance.",
          ],
          Calendar2: [
            "year-end and new year holidays",
            "※It may be temporarily closed,",
            "please check the latest",
            "information on the official website",
            "in advance.",
          ],
          price: "700",
        },
        {
          id: 15,
          areaType: 3,
          areaNAme: "HYOGO",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/JENOVA_A.jpg",
          ticketName: [
            "Awaji Jenova Line Ferry",
            "One Way Ticket",
            "(Akashi to Iwaya)",
          ],
          ticketName3: [
            "Awaji Jenova Line Ferry One Way Ticket",
            "(Akashi to Iwaya)",
          ],
          ticketName4: [
            "Awaji Jenova Line Ferry One Way Ticket",
            "(Akashi to Iwaya)",
          ],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/JENOVA_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/JENOVA_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/JENOVA_C.jpg",
          ],
          Doer: ["8 min walk from JR Akashi Station"],
          time: [
            "(weekdays)",
            "Akashi Cruising Boarding Time：",
            "9:00 AM ～ 17:00 PM",
            "Iwaya Cruising Boarding Time：",
            "9:00 AM ～ 17:00 PM",
            "(Saturday・Sunday・Holiday)",
            "Akashi Cruising Boarding Time：",
            "9:00 AM ～ 17:00 PM",
            "Iwaya Cruising Boarding Time：",
            "9:00 AM ～ 17:00 PM",
            "※Please check the official website",
            "for the detailed timetable.",
          ],
          Http: "https://www.am12.jp/",
          Faci: [
            "Akashi Cruise Boarding Location：",
            "2-Chome-10 Honmachi,",
            "Akashi, Hyogo Prefecture",
            "Iwaya Boarding Location：",
            "925-27Iwaya, Awaji, Hyogo Prefecture",
            "※JENOVA Cruise allows passengers to",
            "board on either side of the ship.",
          ],
          Faci2: [
            "Akashi Cruise Boarding Location：",
            "2-Chome-10 Honmachi,",
            "Akashi, Hyogo Prefecture",
            "Iwaya Boarding Location：",
            "925-27Iwaya, Awaji, Hyogo Prefecture",
            "※JENOVA Cruise allows passengers to",
            "board on either side of the ship.",
          ],
          Calendar: ["Operations may be suspended due", "to bad weather"],
          Calendar2: ["Operations may be suspended due", "to bad weather"],
          price: "530",
        },
        {
          id: 16,
          activeId: 1,
          areaType: 3,
          areaNAme: "SHIGA",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/changbin_A.jpg",
          ticketName: ["Nagahama Roman Passport "],
          ticketName3: ["Nagahama Roman Passport "],
          ticketName4: ["Nagahama Roman Passport "],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/changbin_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/changbin_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/changbin_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/changbin_D.jpg",
          ],
          Doer: ["inside JR Nagahama Station"],
          time: ["Nagahama Tourist Information Center 9:15 AM ~ 16:45 PM"],
          Http: "https://kitabiwako.jp/spot/spot_8966",
          Faci: [
            "1-5 Kitafunacho, Nagahama, Shiga, inside JR Nagahama Station",
          ],
          Faci2: [
            "1-5 Kitafunacho, Nagahama, Shiga,",
            "inside JR Nagahama Station",
          ],
          Calendar: ["New Years"],
          Calendar2: ["New Years"],
          price: "1,200",
        },
        {
          id: 17,
          activeId: 1,
          areaType: 3,
          areaNAme: "SHIGA",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/dajin_A.jpg",
          ticketName: [" Otsu Prince Hotel 37F Lake View Dining 'Biona' Lunch"],
          ticketName3: [
            " Otsu Prince Hotel 37F Lake View Dining 'Biona' Lunch",
          ],
          ticketName4: [
            " Otsu Prince Hotel 37F Lake View Dining 'Biona' Lunch",
          ],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/dajin_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/dajin_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/dajin_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/dajin_D.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/dajin_E.jpg",
          ],
          Doer: ["10 min free shuttle bus from JR Otsu Station"],
          time: [
            "Business Hours:：11:30 AM ～ 15:00 PM",
            "Dining time： 11:30 AM ～ 13:00 PM",
            "13:30 AM ～ 15:00 PM",
            "(Dining time limit 90 min,2 time slots)",
          ],
          Http: "https://www.princehotels.com/otsu/restaurants/lake-view-dining-biona/?_ga=2.154385208.1819375824.1598513837-2140798614.1596609460",
          Faci: [
            "4-7-7, Nionohama, Otsu City, Shiga, Japan  Otsu Prince Hotel (37F Lake View Dining Biona)",
          ],
          Faci2: [
            "4-7-7, Nionohama, Otsu City, Shiga, Japan  Otsu Prince Hotel",
            "(37F Lake View Dining Biona)",
          ],
          Calendar: ["Please see the website for operating days"],
          Calendar2: ["Please see the website for operating days"],
          price: "3,000",
        },
        {
          id: 18,
          activeId: 1,
          areaType: 3,
          areaNAme: "SHIGA",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/pipahu_A.jpg",
          ticketName: [
            "Chikubushima Island Cruise (Hikone Port – Chikubushima",
          ],
          ticketName2: ["* Round Trip)"],
          ticketName3: [
            "Chikubushima Island Cruise (Hikone Port – Chikubushima",
            "* Round Trip)",
          ],
          ticketName4: [
            "Chikubushima Island Cruise (Hikone Port – Chikubushima * Round Trip)",
          ],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/pipahu_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/pipahu_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/pipahu_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/pipahu_D.jpg",
          ],
          Doer: [
            "Approx. 8 minutes from JR Hikone Station by free shuttle bus, get off at the Hikone port stop",
          ],
          time: ["weekends and holidays"],
          Http: "http://www.ohmitetudo.co.jp/marine/",
          Faci: ["3755, Matsubaracho, Hikone-shi, Shiga (Hikone Port)"],
          Faci2: ["3755, Matsubaracho, Hikone-shi, Shiga (Hikone Port)"],
          Calendar: ["Closed：weekdays, 12/30 ～ 12/31"],
          Calendar2: ["Closed：weekdays, 12/30 ～ 12/31"],
          price: "3,000",
        },
        {
          id: 19,
          areaType: 3,
          areaNAme: "SHIGA",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/bapanshan_A.jpg",
          ticketName: ["Hachimanyama Ropeway", "Round-Trip Ticket "],
          ticketName3: ["Hachimanyama Ropeway", "Round-Trip Ticket "],
          ticketName4: ["Hachimanyama Ropeway", "Round-Trip Ticket "],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/bapanshan_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/bapanshan_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/bapanshan_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/bapanshan_D.jpg",
          ],
          Doer: [
            "7 min on the bus bound for Chomeiji Temple leaving from boarding point 6 at the north exit of JR Omihachiman Station, get off at the Osugi-cho Hachimanyama Ropeway Entrance stop",
          ],
          time: ["9:00 AM ~ 5:00 PM", "*Last return trip at 4:30 PM"],
          Http: "http://www.ohmitetudo.co.jp/hachimanyama/open-price/index.html/",
          Faci: ["Miyauchicho, Omihachiman-shi, Shiga (Ticket Counter)"],
          Faci2: ["Miyauchicho, Omihachiman-shi, Shiga (Ticket Counter)"],
          Calendar: ["Open all year"],
          Calendar2: ["Open all year"],
          price: "890",
        },
        {
          id: 20,
          areaType: 3,
          areaNAme: "WAKAYAMA",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/chuanben_A.jpg",
          ticketName: [
            "Kushimoto Marine Park Aquarium & Undersea Observation Tower",
            "Admission Ticket",
          ],
          ticketName3: [
            "Kushimoto Marine Park Aquarium & Undersea Observation Tower Admission Ticket",
          ],
          ticketName4: [
            "Kushimoto Marine Park Aquarium & Undersea Observation Tower Admission Ticket",
          ],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/chuanben_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/chuanben_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/chuanben_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/chuanben_D.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/chuanben_E.jpg",
          ],
          Doer: ["12 min by free shuttle bus from JR Kushimoto Station"],
          time: ["9:00 AM ~ 4:30 PM (Last entry 4:00 PM)"],
          Http: "http://www.kushimoto.co.jp/english/",
          Faci: [
            "1157 Arida, Kushimoto, Higashimuro District, Wakayama (Ticket Counter)",
          ],
          Faci2: [
            "1157 Arida, Kushimoto, Higashimuro District, Wakayama (Ticket Counter)",
          ],
          Calendar: ["Open all year"],
          Calendar2: ["Open all year"],
          price: "1,800",
        },
        {
          id: 21,
          areaType: 3,
          areaNAme: "WAKAYAMA",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/zhichuan_A.jpg",
          copyright: "「DESIGNED BY EIJI MITOOKA+DON DESIGN ASSOCIATES」",
          ticketName: ["Wakayama Electric Railway Kishigawa Line 1 Day Ticket"],
          ticketName3: [
            "Wakayama Electric Railway Kishigawa Line",
            "1 Day Ticket",
          ],
          ticketName4: [
            "Wakayama Electric Railway Kishigawa Line",
            "1 Day Ticket",
          ],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/zhichuan_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/zhichuan_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/zhichuan_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/zhichuan_D.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/zhichuan_E.jpg",
          ],
          Doer: ["JR Wakayama Station, Platform 9"],
          time: ["Varies between types of train"],
          Http: "https://www.wakayama-dentetsu.co.jp/en/",
          Faci: [
            "5 Chome 2 Misonocho, Wakayama (Wakayama Electric Railway Wakayama Station)",
          ],
          Faci2: [
            "5 Chome 2 Misonocho, Wakayama (Wakayama Electric Railway Wakayama Station)",
          ],
          Calendar: [
            "Please see the website for operating days and",
            "the Station Master Cat's work schedule.",
          ],
          Calendar2: [
            "Please see the website for operating days and the Station Master Cat's work schedule.",
          ],
          price: "800",
        },
        // 山陽山陰
        {
          id: 22,
          areaType: 4,
          areaNAme: "OKAYAMA",
          img: require("@/assets/images/allpass/erdao_A.jpg"),
          tickeImg: [
            require("@/assets/images/allpass/erdao_A.jpg"),
            require("@/assets/images/allpass/erdao_B.jpg"),
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/erdao_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/erdao_D.jpg",
          ],
          ticketName: [
            "Kojima Tour Bus",
            " 1-Day Pass and ",
            "Denim Handmade Experience",
          ],
          ticketName3: [
            "Kojima Tour Bus",
            " 1-Day Pass and ",
            "Denim Handmade Experience",
          ],
          ticketName4: [
            "Kojima Tour Bus",
            " 1-Day Pass and ",
            "Denim Handmade Experience",
          ],
          Doer: [
            "12 mi by bus from JR Kojima Station (exchange at)",
            "JR Kojima Station ticket gates",
          ],
          time: [
            "Exchange: 9:00 AM ~ 17:00 PM",
            "Business Hours:",
            "Denim Handmade Experience:",
            "9:00 AM ~ 18:00 PM",
            "Experience Session:",
            "11:00 AM, 14:00 PM",
            "FUREAI bus / TOKOHAI Bus: Everyday",
            "Kojima Tour Bus: Every Friday, Weekend, and Holiday",
          ],
          Http: "http://shimoden.net/rosen/kikaku/jeans.html",
          Faci: [
            "1 Chome Kojima Ekimae,",
            "Kurashiki, Okayama",
            "(JR Kojima Station boarding place)",
          ],
          Faci2: [
            "1 Chome Kojima Ekimae,",
            "Kurashiki, Okayama (JR Kojima Station boarding place)",
          ],
          Calendar: [
            "Closed:",
            "Denim Handmade Experience:",
            "Year-end and New year",
            "Kojima Tour Bus:",
            "Monday to Thursday",
            "and Year-end and New year",
          ],
          Calendar2: [
            "Closed:",
            "Denim Handmade Experience:",
            "Year-end and New year",
            "Kojima Tour Bus:",
            "Monday to Thursday",
            "and Year-end and New year",
          ],
          price: "1,000",
        },
        {
          id: 23,
          areaType: 4,
          areaNAme: "OKAYAMA",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/gangshan_A.jpg",
          ticketName: [
            "Hotel Granvia Okayama Pasry Chef Featured Sundae & Drink",
          ],
          ticketName3: [
            "Hotel Granvia Okayama Pasry Chef Featured Sundae & Drink",
          ],
          ticketName4: [
            "Hotel Granvia Okayama Pasry Chef Featured Sundae & Drink",
          ],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/gangshan_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/gangshan_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/gangshan_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/gangshan_D.jpg",
          ],
          Doer: ["Near JR Okayama Station"],
          time: ["12:00 PM ~ 17:00 PM (Hotel 1F Lobby)"],
          Http: "https://granvia-oka.co.jp/en/restaurant/#lum",
          Faci: ["1F, 1-5 Ekimotomachi Kita-ku Okayama City (1F Lumière)"],
          Faci2: ["1F, 1-5 Ekimotomachi Kita-ku Okayama City (1F Lumière)"],
          Calendar: ["Open all year"],
          Calendar2: ["Open all year"],
          price: "2,500",
        },
        {
          id: 24,
          areaType: 4,
          areaNAme: "HIROSHIMA",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/daotian_A.jpg",
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/daotian_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/daotian_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/daotian_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/daotian_D.jpg",
          ],
          ticketName: [
            "Shimada Fisheries Oyster Farm Tour & Going Close to",
            "the Itsukushima Shrine",
          ],
          ticketName3: [
            "Shimada Fisheries Oyster Farm Tour & Going Close to the Itsukushima Shrine",
          ],
          ticketName4: [
            "Shimada Fisheries Oyster Farm Tour & Going Close to the Itsukushima Shrine",
          ],
          Doer: [
            "15 min on foot from Miyajimaguchi",
            "Station on Sanyo Main Line（about 10",
            "min walk from the cruise boarding",
            "location）",
          ],
          time: ["10:00 AM ~ 16:00 PM"],
          Http: "https://shimadasuisan.com/kakigoya-en.html",
          Faci: [
            "Miyajimaguchi Nishi 1-2-6, Hatsukaichi, Hiroshima",
            "(Ticket Counter)",
          ],
          Faci2: [
            "Miyajimaguchi Nishi 1-2-6, Hatsukaichi, Hiroshima",
            "(Ticket Counter)",
          ],
          Calendar: ["Open all year"],
          Calendar2: ["Open all year"],
          price: "2,200",
        },
        {
          id: 25,
          areaType: 4,
          areaNAme: "HIROSHIMA",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/laihu_A.jpg",
          ticketName: ["Setoda Touring Pass"],
          ticketName3: ["Setoda Touring Pass"],
          ticketName4: ["Setoda Touring Pass"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/laihu_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/laihu_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/laihu_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/laihu_A.jpg",
          ],
          Doer: ["JR Onomichi Station"],
          time: [
            "9:00 AM ~ 18:00 PM (Onomichi Station Tourist Information Center)",
          ],
          Http: "https://www.ononavi.jp/fan/onotabi/guruseto/ ",
          Faci: [
            "1-1 Higashigoshocho, Onomichi-city, Hiroshima-ken (Onomichi Station Tourist Information)",
          ],
          Faci2: [
            "1-1 Higashigoshocho, Onomichi-city, Hiroshima-ken (Onomichi Station Tourist Information)",
          ],
          Calendar: ["12/29 ～ 1/3"],
          Calendar2: ["12/29 ～ 1/3"],
          price: "4,500",
        },
        {
          id: 26,
          areaType: 4,
          areaNAme: "YAMAGUCHI",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/shankou_A.jpg",
          ticketName: ["Hofu City Sightseeing", "Tour Pass"],
          ticketName3: ["Hofu City Sightseeing", "Tour Pass"],
          ticketName4: ["Hofu City Sightseeing", "Tour Pass"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/shankou_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/shankou_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/shankou_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/shankou_D.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/shankou_E.jpg",
          ],
          Doer: ["Inside JR Hofu Station concourse"],
          time: ["9:00 AM ~ 18:00 PM (Hofu City Tourist Information Center)"],
          Http: "https://visit-hofu.jp/ticket",
          Faci: [
            "1-chome-1-1 Ebisumachi, Hofu, Yamaguchi  Hofu City Tourist Information Center (inside JR Hofu Station)",
          ],
          Faci2: [
            "1-chome-1-1 Ebisumachi, Hofu,",
            "Yamaguchi  Hofu City",
            "Tourist Information Center",
            "(inside JR Hofu Station)",
          ],
          Calendar: ["Open all year"],
          Calendar2: ["Open all year"],
          price: "1,200",
        },
        {
          id: 27,
          areaType: 4,
          areaNAme: "SHIMANE",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/youzhiyuan_A.jpg",
          ticketName: ["YUUSHIEN Garden in Daikonshima"],
          ticketName3: ["YUUSHIEN Garden in Daikonshima"],
          ticketName4: ["YUUSHIEN Garden in Daikonshima"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/youzhiyuan_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/youzhiyuan_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/youzhiyuan_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/youzhiyuan_D.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shanyinshanyang/youzhiyuan_E.jpg",
          ],
          Doer: [
            "About 50 minutes by bus. Take the bus from boarding point 6 at JR Matsue Station.",
          ],
          time: [
            "10:00 AM ~ 17:00 PM (Last entry 30 minutes before closing)",
            "*Hours may be extended",
          ],
          Http: "https://www.yuushien.com/language/en.html",
          Faci: ["1260-2, Hanyu, Yatsukacho, Matsue, Shimane"],
          Faci2: ["1260-2, Hanyu, Yatsukacho, Matsue, Shimane"],
          Calendar: ["Year-end (12/30 ～ 12/31)"],
          Calendar2: ["Year-end (12/30 ～ 12/31)"],
          price: "1,000",
        },
        // 石川
        {
          id: 28,
          areaType: 5,
          areaNAme: "ISHIKAWA",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shichuan/guozhisen_A.jpg",
          ticketName: ["Kaga Traditional Handicrafts Village Yunokuni no Mori"],
          ticketName3: [
            "Kaga Traditional Handicrafts Village Yunokuni no Mori",
          ],
          ticketName4: [
            "Kaga Traditional Handicrafts Village Yunokuni no Mori",
          ],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shichuan/guozhisen_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shichuan/guozhisen_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shichuan/guozhisen_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shichuan/guozhisen_D.jpg",
          ],
          Doer: [
            "Approx. 40 minutes on the CANBUS Mountain route from",
            "JR Kagaonsen Station",
          ],
          time: ["9:00 AM ～ 16:30 PM", "（ Last entry 15:30 PM）"],
          Http: "https://www.yunokuni.jp/mori/?lang=en",
          Faci: [
            "Na 3-3 Awazu-onsen, Komatsu City",
            "Ishikawa (Ticket Counter)",
          ],
          Faci2: [
            "Na 3-3 Awazu-onsen, Komatsu City",
            "Ishikawa (Ticket Counter)",
          ],
          Calendar: [
            "※It may be temporarily closed,",
            "please check the latest",
            "information on the official website in advance.",
          ],
          Calendar2: [
            "※It may be temporarily closed,",
            "please check the latest",
            "information on the official website in advance.",
          ],
          price: "550",
        },
        {
          id: 29,
          areaType: 5,
          areaNAme: "ISHIKAWA",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shichuan/CANBUS_A.jpg",
          ticketName: ["Kaga Tour Buss CANBUS", "1-Day Ticket"],
          ticketName3: ["Kaga Tour Buss CANBUS", "1-Day Ticket"],
          ticketName4: ["Kaga Tour Buss CANBUS", "1-Day Ticket"],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shichuan/CANBUS_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shichuan/CANBUS_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shichuan/CANBUS_C.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/shichuan/CANBUS_D.jpg",
          ],
          Doer: ["Near JR Kagaonsen Station"],
          time: ["8:30 AM ~ 18:00 PM"],
          Http: "http://www.kaga-canbus.jp/english.php",
          Faci: [
            "Ri 1-3, Sakumimachi, Kaga-shi, Ishikawa-ken",
            "(Machizukuri Ticket Counter)",
          ],
          Faci2: [
            "Ri 1-3, Sakumimachi, Kaga-shi, Ishikawa-ken (Machizukuri Ticket Counter)",
          ],
          Calendar: ["Open all year"],
          Calendar2: ["Open all year"],
          price: "1,100",
        },
      ],
      md3: [
        {
          id: 1,
          name: "ALL AREA",
          num: 29,
          areaType: 0,
        },
        {
          id: 2,
          name: "OSAKA",
          areaType: 2,
        },
        {
          id: 3,
          name: "KYOTO",
          areaType: 1,
        },
      ],
      md4: [
        {
          id: 4,
          name: "ISHIKAWA",
          areaType: 5,
        },
      ],
      md5: [
        {
          id: 5,
          name: "SHIGA．HYOGO．WAKAYAMA",
          areaType: 3,
        },
      ],
      md7: [
        {
          id: 6,
          name: "SANIN．SANYO",
          areaType: 4,
        },
      ],
    };
  },
  created() {
    this.showArea();
  },
  mounted() {
    window.addEventListener("resize", this.setbanH, false);
  },
  destroyed() {
    window.removeEventListener("resize", this.setbanH, false);
  },
  methods: {
    // 手指触摸滑动事件
    touchstart(e) {
      this.startX = e.touches[0].clientX;
      this.startY = e.touches[0].clientY;
    },
    touchmove(e) {
      this.moveX = e.touches[0].clientX;
      this.moveY = e.touches[0].clientY;
      this.startX - this.moveX <= 0
        ? console.log("你在往右滑")
        : console.log("你在往左滑");
      if (this.startX - this.moveX >= 150) {
        // 右滑触发
        this.drawer = true;
      }
    },
    touchmove1(e) {
      this.moveX = e.touches[0].clientX;
      this.moveY = e.touches[0].clientY;
      this.startX - this.moveX <= 0
        ? console.log("你在往右滑")
        : console.log("你在往左滑");
      if (this.startX - this.moveX <= -100) {
        // 右滑触发
        this.drawer = false;
      }
    },
    touchmove2(e) {
      this.moveX = e.touches[0].clientX;
      this.moveY = e.touches[0].clientY;
      this.startX - this.moveX <= 0
        ? console.log("你在往右滑")
        : console.log("你在往左滑");
      if (this.startX - this.moveX <= -100) {
        // 右滑触发
        this.innerDrawer = false;
      }
    },
    loading() {
      this.showBtn = true;
      this.$forceUpdate();
    },
    setbanH() {
      this.$nextTick(() => {
        this.banH = `${this.$refs.imgH[0].height}px`;
      });
    },
    change(item) {
      this.active = item.id;
      if (this.active === 1) {
        this.ticketDescribe = "Ticket Valid  (29 facilities)(29项设施)";
        this.planName = ["Kansai Wide-Area Pass", "5 Days Plan"];
        this.planPrice = "27,700";
        this.planName2 = ["Kansai-Hiroshima Area Pass", "5 Days Plan"];
        this.planPrice2 = "32,000";
        this.planName3 = "JR-WEST All Area Pass";
        this.planPrice3 = "39,000";
        this.md3[0].num = 29;
        this.showList = this.scenicSpotArr;
        let array = {
          id: 15,
          areaType: 3,
          areaNAme: "HYOGO",
          img: "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/JENOVA_A.jpg",
          ticketName: [
            "Awaji Jenova Line Ferry",
            "One Way Ticket",
            "(Akashi to Iwaya)",
          ],
          ticketName3: [
            "Awaji Jenova Line Ferry One Way Ticket",
            "(Akashi to Iwaya)",
          ],
          ticketName4: [
            "Awaji Jenova Line Ferry One Way Ticket",
            "(Akashi to Iwaya)",
          ],
          tickeImg: [
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/JENOVA_A.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/JENOVA_B.jpg",
            "https://qbcdn.jtrchina.com/tripelletexploreallpass/backdrop/hegeshan/JENOVA_C.jpg",
          ],
          Doer: ["8 min walk from JR Akashi Station"],
          time: [
            "(weekdays)",
            "Akashi Cruising Boarding Time：",
            "9:00 AM ～ 17:00 PM",
            "Iwaya Cruising Boarding Time：",
            "9:00 AM ～ 17:00 PM",
            "(Saturday・Sunday・Holiday)",
            "Akashi Cruising Boarding Time：",
            "9:00 AM ～ 17:00 PM",
            "Iwaya Cruising Boarding Time：",
            "9:00 AM ～ 17:00 PM",
            "※Please check the official website",
            "for the detailed timetable.",
          ],
          Http: "https://www.am12.jp/",
          Faci: [
            "Akashi Cruise Boarding Location：",
            "2-Chome-10 Honmachi,",
            "Akashi, Hyogo Prefecture",
            "Iwaya Boarding Location：",
            "925-27Iwaya, Awaji, Hyogo Prefecture",
            "※JENOVA Cruise allows passengers to",
            "board on either side of the ship.",
          ],
          Faci2: [
            "Akashi Cruise Boarding Location：",
            "2-Chome-10 Honmachi,",
            "Akashi, Hyogo Prefecture",
            "Iwaya Boarding Location：",
            "925-27Iwaya, Awaji, Hyogo Prefecture",
            "※JENOVA Cruise allows passengers to",
            "board on either side of the ship.",
          ],
          Calendar: ["Operations may be suspended due", "to bad weather"],
          Calendar2: ["Operations may be suspended due", "to bad weather"],
          price: "530",
          content: "",
          content2: "",
        };
        this.id15 = this.showList.findIndex((item) => {
          if (item.id === 14) {
            return true;
          }
        });
        this.showList.splice(14, 0, array);
      } else {
        this.ticketDescribe = "Ticket Valid  (16 facilities)";
        this.planName = ["Kansai Area Pass 3 Days Plan"];
        this.planPrice = "19,300";
        this.planName2 = ["Kansai Wide Area Pass 5 Days"];
        this.planPrice2 = "22,700";
        this.planName3 = "";
        this.planPrice3 = "";
        this.md3[0].num = 16;
        this.showList = this.scenicSpotArr;
        let id15 = this.showList.findIndex((item) => {
          if (item.id === 15) {
            return true;
          }
        });
        this.showList.splice(id15, 1);
      }
      this.activeAreaArr = []; //清除已选中的地区id
      this.activeArr = [1]; //切换探游券时 默认全部
    },
    openSmall(item) {
      this.ticketDetials = item;
      this.innerDrawer = true;
    },
    showArea() {
      this.showList = this.scenicSpotArr;
    },
    activeArea(item) {
      let index = 0;
      this.showList = [];
      if (item.areaType === 0) {
        // 选择了所有
        this.activeArr = [1]; //所有
        this.activeAreaArr = [];
        this.active === 1
          ? (this.showList = this.scenicSpotArr)
          : (this.showList = this.scenicSpotArr); //判断是否西日本
        return;
      }
      if (item.areaType !== 0) {
        // 没有选择全部地区并且选的是西日本
        let alldelIndex = this.checkArray(this.activeArr, 1); //找到复选地区中所有地区的下标
        alldelIndex !== -1 ? this.activeArr.splice(alldelIndex, 1) : ""; //如果有所有地区的下标就删除
        let activeArrDelIndex = this.checkArray(this.activeArr, item.id); //查找复选地区是否已经存在
        let activeAreaArrDelIndex = this.checkArray(
          this.activeAreaArr,
          item.areaType
        ); //查找复选地区是否已经存在
        activeArrDelIndex === -1
          ? this.activeArr.push(item.id)
          : this.activeArr.splice(activeArrDelIndex, 1); //如果目前地区存在就删除，不然就加入
        activeAreaArrDelIndex === -1
          ? this.activeAreaArr.push(item.areaType)
          : this.activeAreaArr.splice(activeAreaArrDelIndex, 1); //查找复选地区是否已经存在
        this.scenicSpotArr.forEach((item1) => {
          if (item.areaType === item1.areaType) {
            // 去重
            let newArr = [];
            for (let i = 0; i < this.activeArr.length; i++) {
              newArr.indexOf(this.activeArr[i]) == -1
                ? newArr.push(this.activeArr[i])
                : "";
            }
            this.activeArr = newArr;
          }
        });
        this.activeAreaArr = this.unique(this.activeAreaArr); //景点属性去重
        this.scenicSpotArr.forEach((item11) => {
          this.activeAreaArr.forEach((item12) => {
            item12 == item11.areaType ? this.showList.push(item11) : "";
            item12 == item11.areaType ? console.log(item11) : "";
          });
        });
      } else {
        this.activeArr = [1];
        this.showList = this.scenicSpotArr;
      }
    },
    unique(arr) {
      var result = [];
      for (var i = 0; i < arr.length; i++) {
        for (var j = i + 1; j < arr.length; j++) {
          if (arr[i] === arr[j]) {
            j = ++i;
          }
        }
        result.push(arr[i]);
      }
      return result;
    },
    checkArray(arr, target) {
      return arr.indexOf(target);
    },
    handleClose(done) {
      done();
    },
    splitBatName(str) {
      return str;
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/css/index2.scss";
.touch {
  touch-action: pan-y !important;
}
.loadImg {
  width: 100%;
  height: auto;
}
.main-package {
  z-index: 0 !important;
}
::v-deep .el-drawer__body {
  overflow: auto;
}
::v-deep .el-drawer__container ::-webkit-scrollbar {
  display: none;
}
::v-deep .el-carousel__indicator--horizontal {
  padding: 0 4px;
}

::v-deep .el-drawer {
  width: 100% !important;
}

::v-deep .el-drawer__header {
  display: block;
  color: rgb(255, 152, 0);
  padding: 14px 16px;
  margin-bottom: 0;
  background-color: rgb(255, 237, 147);
  position: relative;
  height: 27px;
  line-height: 27px;
}

::v-deep .el-icon-close:before {
  content: "\e6de";
  font-size: 18px;
  font-weight: bold;
  border: 2px solid #fb7b05;
  border-radius: 20px;
}

::v-deep .el-drawer__close-btn {
  border: 0;
  outline: none;
}
::v-deep .el-carousel__button {
  display: block;
  opacity: 0.48;
  width: 7px;
  height: 7px;
  background-color: #bcbfbf;
  border: none;
  outline: 0;
  padding: 0;
  margin: 0;
  cursor: pointer;
  transition: 0.3s;
  border-radius: 50%;
}

::v-deep .el-carousel__indicator.is-active button {
  width: 7px;
  height: 7px;
  background-color: #ff8033;
  border-radius: 50%;
}

::v-deep .el-carousel__indicators--horizontal {
  bottom: 4%;
  left: 50%;
  transform: translateX(-50%);
}
.area-title {
  width: 327px !important;
  padding: 10px;
  border-radius: 15px;
  background: #fff;
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.area-title1 {
  width: 327px;
  display: flex;
  margin-bottom: 13px;
  align-items: center;
  justify-content: center;
}
.area-title1:nth-child(2) {
  margin-bottom: 13px;
}
.area-title1:nth-child(3) {
  margin-bottom: 0;
}
.area-title-item span {
  display: inline-block;
  width: 76px;
}
.area-title-item1 span {
  display: inline-block;
  width: 124px;
}
.area-title-item2 span {
  text-align: center;
  display: inline-block;
  width: 250px !important;
}
.area-title-item:nth-child(2) {
  border-right: 0 !important;
}
.area-title-item,
.area-title-item2 {
  width: 95px;
  font-family: "GenSenRoundedTW-M";
  font-weight: normal;
  font-size: 14px;
  letter-spacing: 0.01em;
  line-height: 16.8px;
  text-align: center;
  color: #ff7b00;
  border-right: 1px solid #ff7b00;
  text-align: center;
}
.area-title-item2 {
  border-right: 0;
  width: 340px;
}
.area-title-item1 {
  font-family: "GenSenRoundedTW-M";
  width: 136px !important;
  text-align: center;
}
.riding-bottom-t:nth-child(2) {
  color: #1ea2e6 !important;
}
@media screen and (max-width: 360px) {
  ::v-deep .el-carousel__indicators--horizontal {
    bottom: 19%;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>