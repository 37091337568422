<template>
  <div class="box">
    <allpassMb v-if="screenWidth < 765" class="is-mb" />
    <allpassPc v-else class="is-pc" />
  </div>
</template>

<script>
import allpassPc from '@/views_pc/allpass/en'
import allpassMb from '@/views_mb/allpass/en'
export default {
  components: { allpassPc, allpassMb },
  data() {
    return {
      screenWidth: document.body.clientWidth
    }
  },
  mounted() {
    const that = this
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth
        that.screenWidth = window.screenWidth
      })()
    }
  },
  watch: {
    screenWidth(val) {
      this.screenWidth = val
      // console.log(val)
    }
  },
  methods: {}
}
</script>
<style lang='scss' scoped>
.box::-webkit-scrollbar{
  width: 0 !important;
  height: 0;
  display: none;
}
.is-pc {
  display: block;
}
.is-mb {
  display: none;
}
@media (max-width: 766px) {
  .is-pc {
    display: none;
  }
  .is-mb {
    display: block;
  }
}
</style>
